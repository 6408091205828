import type { SessionQuestion } from "@/services/api/learning-paths/get"
import Latex from "react-latex"
import { Check, Loader2, XIcon } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { useQuestionTypes } from "@/modules/learning-paths/hooks/use-question-types"

enum DEFAULT_OPTIONS {
  TRUE = "verdadero",
  FALSE = "falso",
}

interface TrueFalseQuestionProps {
  currentQuestion: SessionQuestion
  isLoading: boolean
  handleNextStep: () => void
  handleFeedback(): Promise<void>
}

export default function TrueFalseQuestion({
  currentQuestion,
  isLoading,
  handleNextStep,
  handleFeedback,
}: TrueFalseQuestionProps) {
  const { selectedOption, currentAnswer, handleOptionClick, setSelectedOption, setOpenAnswer } =
    useQuestionTypes({
      currentQuestion,
    })
  const defaultOptions = [DEFAULT_OPTIONS.TRUE, DEFAULT_OPTIONS.FALSE]

  return (
    <div className="mt-12 grid w-full gap-4">
      <div className="flex items-center gap-4">
        <p className="text-lg font-medium">
          <Latex>{currentQuestion.question}</Latex>
        </p>
      </div>
      <div className="flex w-full flex-col gap-4">
        {defaultOptions?.map((option: string, index: number) => {
          const formatOption = option.toLowerCase() === DEFAULT_OPTIONS.TRUE
          const formatAnswer = currentQuestion.answer.toLowerCase() === DEFAULT_OPTIONS.FALSE

          return (
            <div
              key={`option-${index}`}
              className={`flex w-full cursor-pointer items-center justify-between gap-4 rounded-xl border p-4 shadow-lg ${
                index === selectedOption ? "bg-orange-300" : ""
              }`}
              onClick={() => handleOptionClick(option, currentQuestion.index, index)}
            >
              <p>{option}</p>

              {currentAnswer?.score !== undefined && currentAnswer?.score && (
                <div>
                  {formatOption === formatAnswer ? (
                    <Check className="rounded-full bg-green-500 p-2 text-white" size={32} />
                  ) : (
                    <XIcon className="rounded-full bg-red-500 p-2 text-white" size={32} />
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
      {currentAnswer && (
        <div className="flex flex-col items-end gap-8">
          {currentAnswer?.score !== undefined && currentAnswer?.score ? (
            <Button
              className="text-md w-fit"
              onClick={() => {
                setSelectedOption(null)
                setOpenAnswer("")
                handleNextStep()
              }}
            >
              Siguiente
            </Button>
          ) : (
            <Button disabled={isLoading} className="text-md w-fit" onClick={handleFeedback}>
              {isLoading && <Loader2 className="mr-2 animate-spin" />}
              Verificar
            </Button>
          )}
          {currentAnswer?.score !== undefined && currentAnswer?.score && (
            <div className="flex w-full flex-col items-start gap-4 rounded-xl border border-black p-6">
              <span className="text-lg font-medium"> Resultado </span>
              <div className="flex flex-col items-center gap-4 sm:flex-row sm:gap-8">
                <Badge
                  variant="default"
                  className="flex w-fit items-center justify-center gap-1 bg-orange-400 text-xl sm:w-28"
                >
                  {currentAnswer?.score} / 10
                </Badge>
                <p className="text-sm font-light sm:w-[85%]">{currentAnswer?.feedback}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
