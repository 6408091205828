import { create, type StateCreator } from "zustand"

interface PlanModalState {
  isOpen: boolean
  step: number
}

interface PlanModalActions {
  setStep: (step: number) => void
  setOpen: () => void
  setClose: () => void
}

const initialState: StateCreator<PlanModalState & PlanModalActions> = (set) => ({
  isOpen: false,
  step: 0,
  setClose: () => set({ isOpen: false }),
  setOpen: () => set({ isOpen: true }),
  setStep: (step) => set({ step }),
})

const usePlanModal = create<PlanModalState & PlanModalActions>(initialState)

export default usePlanModal
