import React from "react"
import { Card, CardContent } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Trash } from "lucide-react"
import useDeleteTheme from "@/modules/app/queries/use-delete-theme"
import type { ListTheme } from "@/services/api/learning-paths/update"

interface ThemeProps {
  theme: ListTheme
  index: number
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDelete: () => void
  children: React.ReactNode
}

export default function Theme({ theme, onNameChange, onDelete, children }: ThemeProps) {
  const { mutateAsync } = useDeleteTheme(theme.id ?? "")

  function handleDelete() {
    if (!theme.id) {
      onDelete()
      return
    }
    mutateAsync().then(onDelete)
  }

  return (
    <Card className="w-full">
      <CardContent className="pt-6">
        <div className="flex items-center gap-2">
          <Input type="text" value={theme.name} onChange={onNameChange} className="flex-1" />
          <Button variant="ghost" onClick={handleDelete}>
            <Trash className="h-4 w-4" />
          </Button>
        </div>
        {children}
      </CardContent>
    </Card>
  )
}
