import { create } from "./create"
import { downloadSigned } from "./downloadSigned"
import { update } from "./update"
import { deleteDocument } from "./delete"
import { flashcards } from "./flashcards"
import { createPresignedUrl } from "./create-presigned-url"
import { uploadFileToPresignedUrl } from "./upload-file-to-presigned-url"
import diagnoses from "./diagnoses"
import themes from "./themes"

export const documents = {
  create,
  downloadSigned,
  update,
  delete: deleteDocument,
  createPresignedUrl,
  uploadFileToPresignedUrl,
  flashcards,
  diagnoses,
  themes,
}
