import { info } from "./info"
import { onboarding } from "./onboarding"
import { getAll } from "./getAll"
import { update } from "./update"
import { updateProfile } from "./update-profile"
import { updateLives } from "./update-lives"

export const user = {
  getAll,
  updateProfile,
  info,
  onboarding,
  update,
  updateLives,
}
