import { client } from "@/config/client"
import type { Document } from "../../documents/get"
import type { Activity } from "../../activities/get"

export enum STUDENT_ROLE {
  SELF_TAUGHT = "SELF_TAUGHT",
  UNIVERSITY = "UNIVERSITY",
  TERTIARY = "TERTIARY",
  SECONDARY = "SECONDARY",
}

export type LearningSession = {
  id: string
  name: string
  score?: number
  index?: number
  learningPathSubthemeId: string
  learningPathId: string
  createdAt: Date
  updatedAt: Date
}

export type UserPoint = {
  id: number
  points: number
}

export type UserInfo = {
  id: string
  name: string
  email: string
  country: string
  role: "STUDENT" | "TEACHER" | "ADMIN"
  avatar: string
  studentRole: STUDENT_ROLE
  lives: number
  plan: "PRO" | "FREE"
  activities: Activity[]
  learningSessions: LearningSession[]
  documents: Document[]
  userPoints: UserPoint[]
  refillLivesAt: string
  diagnoseUses: number
  university?: string | null
  career?: string | null
  movie?: string | null
  interests?: string | null
}

export type GetUserInfoResponse = {
  user: UserInfo
}

export function get() {
  return client.get<GetUserInfoResponse>("/v1/users/info")
}
