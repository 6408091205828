export type StreamChunk = {
  value: Uint8Array
  done: boolean
}

export type ModelChoice = {
  delta?: {
    content?: string
  }
}

export type ModelResponse = {
  choices?: ModelChoice[]
  error?: {
    message: string
  }
}

function splitOnce(input: string, separator: string): [string, string] {
  const index = input.indexOf(separator)

  if (index === -1) {
    return [input, ""]
  }

  const firstPart = input.substring(0, index)
  const secondPart = input.substring(index + separator.length)

  return [firstPart, secondPart]
}

export const processLine = (text: string): [parsed: string, rest: string] => {
  try {
    const split = splitOnce(text, "\n")
    const textToParse = split[0]
    const rest = split[1]
    const parsed: ModelResponse = JSON.parse(textToParse)
    if (parsed.error) {
      throw new Error(parsed.error.message || "Error en la respuesta del modelo")
    }

    return [parsed.choices?.[0]?.delta?.content || "", rest]
  } catch (e) {
    if (!(e instanceof SyntaxError)) {
      throw e
    }

    return ["", text]
  }
}

export const processChunk = (
  previousRest: string,
  chunk: Uint8Array,
  decoder: TextDecoder,
): [parsed: string, rest: string] => {
  let text = previousRest + decoder.decode(chunk, { stream: true })
  let result = ""
  while (true) {
    const [parsed, rest] = processLine(text)
    if (parsed == "") {
      return [result, rest]
    }
    result += parsed
    text = rest
  }
}

export const readStream = async (
  reader: ReadableStreamDefaultReader<Uint8Array>,
): Promise<{ content: string; hasValidContent: boolean }> => {
  const decoder = new TextDecoder()
  let content = ""
  let hasValidContent = false
  let rest = ""
  try {
    while (true) {
      const { value, done }: ReadableStreamReadResult<Uint8Array> = await reader.read()
      const chunk: StreamChunk = { value: value || new Uint8Array(), done: done }

      if (chunk.done) {
        break
      }

      const [processedContent, newRest] = processChunk(rest, chunk.value, decoder)
      if (processedContent) {
        content += processedContent
        hasValidContent = true
      }
      rest = newRest
    }
  } finally {
    reader.releaseLock()
  }

  return { content, hasValidContent }
}

export const getMessage = async (
  reader: ReadableStreamDefaultReader<Uint8Array>,
): Promise<string> => {
  try {
    const { content, hasValidContent } = await readStream(reader)
    if (!hasValidContent || !content.trim()) {
      throw new Error("No se recibió contenido válido del modelo")
    }

    return content
  } catch (error) {
    reader.cancel()
    throw error
  }
}
