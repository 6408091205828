import { client } from "@/config/client"
import type { QUESTION_TYPE } from "./themes/subthemes/get"

export interface Theme {
  id: string
  name: string
  score: number | null
  index: number | null
  learningPathId: string
  createdAt: string
  updatedAt: string
  subthemes?: Subtheme[]
}

export interface Subtheme {
  id: string
  name: string
  index: number
  components: string | null
  score: number | null
  learningPathThemeId: string
  sessionQuestions: SessionQuestion[]
  createdAt: string
  updatedAt: string
  sessions: any[]
}

export interface SessionQuestion {
  type: QUESTION_TYPE
  index: number
  answer: string
  options: string[]
  question: string
}

export interface LearningPath {
  id: string
  name: string
  description: string | null
  score: number | null
  additionalInfo: string | null
  bibliography: string | null
  userId: string
  createdAt: string
  updatedAt: string
  themes?: Theme[]
}

export interface GetLpResponse {
  learningPaths: LearningPath[]
}

export interface GetLpParams {
  limit: number
  offset: number
  theme: string
  subtheme: string
}

export function get(params: Partial<GetLpParams>) {
  return client.get<GetLpResponse>("/v1/learning-paths", { params })
}
