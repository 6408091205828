import answerSessionQuestion, {
  type AnswerSessionQuestionResponse,
  type AnswerSessionQuestionParams,
} from "@/services/api/challenges/answer-session-question"

import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query"
import { useMutation } from "@tanstack/react-query"

const useAnswerSessionQuestionMutation = (
  options?: Omit<
    UseMutationOptions<AnswerSessionQuestionResponse, Error, AnswerSessionQuestionParams>,
    "mutationFn"
  >,
): UseMutationResult<AnswerSessionQuestionResponse, Error, AnswerSessionQuestionParams> => {
  return useMutation({
    mutationFn: answerSessionQuestion,
    ...options,
  })
}

export default useAnswerSessionQuestionMutation
