import type { StateCreator } from "zustand"
import { create } from "zustand"

interface State {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const state: StateCreator<State> = (set) => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
})

export const useNoLifeAlert = create<State>(state)

export const { isOpen, setIsOpen } = useNoLifeAlert.getState()
