import { Button } from "@/components/button"
import useCreateChallengeMutation from "@/modules/challenges/queries/use-create-challenge-mutation"
import type { DocumentChallenge } from "@/services/api/challenges/create-challenge"
import { CHALLENGE_TYPE } from "@/services/api/challenges/create-challenge"
import { useQueryClient } from "@tanstack/react-query"
import { X, CircleHelp } from "lucide-react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { Input } from "@/components/ui/input"
import { useEffect, useState } from "react"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"

interface LayoutProps {
  pdfInfo: {
    name: string
    id: string
    link: string
    totalPagesCount: number
  }
}

const Layout = ({ pdfInfo }: LayoutProps) => {
  const { name, id, link, totalPagesCount } = pdfInfo
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutation = useCreateChallengeMutation()
  const { isPending, mutateAsync } = mutation
  const initialToPage = totalPagesCount >= 50 ? 50 : totalPagesCount
  const [pages, setPages] = useState({ fromPage: 1, toPage: initialToPage })
  const { fromPage, toPage } = pages
  const [errorMsg, setErrorMsg] = useState("")
  const { selectedLearningPath } = useLearningPaths()

  const handleOnGenerate = async () => {
    try {
      const payload: DocumentChallenge = {
        type: CHALLENGE_TYPE.DOCUMENT,
        learningPathId: selectedLearningPath.id,
        documentId: id,
        fromPage: fromPage,
        toPage: toPage,
      }
      await mutateAsync(payload)
      queryClient.invalidateQueries({ queryKey: ["userChallenges"] })
      navigate("/challenges")
      toast.success("Desafío creado correctamente")
    } catch {
      toast.error("Ha ocurrido un error al crear el desafío", {
        description: "Intenta de nuevo más tarde",
      })
    }
  }

  const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["e", "E", "+", "-", ",", "."].includes(e.key)) e.preventDefault()
  }

  const handleErrorMsg = () => {
    const isDifferenceGreaterThan50 = Math.abs(toPage - fromPage) > 50

    if (fromPage === 0 || toPage === 0) return setErrorMsg("El inicio o fin no pueden ser 0.")
    if (fromPage === toPage) return setErrorMsg("El inicio y fin no pueden ser iguales.")
    if (fromPage > toPage) return setErrorMsg("El inicio no puede ser mayor al fin.")
    if (toPage > totalPagesCount)
      return setErrorMsg("El fin no puede ser mayor al largo total del documento.")
    if (isNaN(toPage) || isNaN(fromPage)) return setErrorMsg("Inicio y fin no pueden ser vacíos.")
    if (isDifferenceGreaterThan50) return setErrorMsg("Seleccioná un rango de máximo 50 páginas.")
    return setErrorMsg("")
  }

  useEffect(() => {
    handleErrorMsg()
  }, [pages])

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setPages({
      ...pages,
      [e.target.name]: value === "" ? NaN : Number(value),
    })
  }

  return (
    <div className="size-full">
      {isPending ? (
        <div className="flex size-full flex-col items-center justify-center gap-6 bg-amber-50">
          <div className="h-20 w-20 animate-spin rounded-full border-8 border-transparent border-t-orange-500 duration-500"></div>
          <span className="text-sm font-medium text-gray-500">Creando desafío</span>
        </div>
      ) : (
        <>
          <div className="mb-2 flex items-center gap-4 p-4">
            <h6 className="w-full truncate text-lg font-semibold" title={name}>
              {name}
            </h6>

            <Link
              to="/challenges/new"
              className="flex w-fit shrink-0 items-center justify-center gap-1 text-sm font-bold text-gray-500"
            >
              <X className="text-gray-500" />
            </Link>
          </div>

          <div className="mx-auto flex w-full max-w-[1100px] items-center justify-between gap-5 px-4">
            <div className="flex w-full items-center gap-5">
              <div className="flex items-center justify-center gap-2">
                <span className="shrink-0 text-sm font-bold">Rango de páginas</span>

                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger className="size-full cursor-default">
                      <CircleHelp className="text-gray-500" size={16} />
                    </TooltipTrigger>
                    <TooltipContent className="flex select-none flex-col items-center justify-center rounded border-none bg-gray-800 px-3 py-1.5 text-center text-sm font-normal text-white shadow-none">
                      <p>Elegí las páginas donde esté</p>
                      <p>el contenido que quieras dominar.</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>

              <div className="flex items-center justify-center gap-2">
                <span className="text-sm font-medium">Desde</span>
                <Input
                  type="number"
                  className="w-[60px] appearance-none rounded-[6px] bg-white px-3 py-2 text-sm shadow-[0px_0.5px_2.5px_0px_rgba(0,0,0,0.3)] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  value={pages.fromPage}
                  name="fromPage"
                  onChange={handleOnChangeInput}
                  onKeyDown={blockInvalidChar}
                />

                <span className="text-sm font-medium">hasta</span>
                <Input
                  type="number"
                  className="w-[60px] appearance-none rounded-[6px] bg-white px-3 py-2 text-sm shadow-[0px_0.5px_2.5px_0px_rgba(0,0,0,0.3)] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  value={pages.toPage}
                  name="toPage"
                  onChange={handleOnChangeInput}
                  onKeyDown={blockInvalidChar}
                />
              </div>
            </div>

            <div className="fixed bottom-0 left-0 z-10 w-full bg-white p-4 md:static md:w-fit md:p-0">
              <Button
                type="button"
                onClick={handleOnGenerate}
                className="w-full rounded-[8px] md:w-auto"
                disabled={!!errorMsg || isPending}
                loading={isPending}
              >
                Generar desafío
              </Button>
            </div>
          </div>

          {errorMsg && <p className="m-0 px-4 text-xs text-red-500">{errorMsg}</p>}

          <div className="mx-auto mt-5 h-[73vh] w-full max-w-[1100px] overflow-hidden px-4">
            <iframe src={link} width="100%" height="100%" title={name} />
          </div>
        </>
      )}
    </div>
  )
}

export default Layout
