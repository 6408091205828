import { Button } from "@/components/button"
import { PlusIcon } from "lucide-react"
import { useState } from "react"
import CreateLearningPathDialog from "./create-learning-path-modal"

interface CreateLearningPathProps {
  onCreate: () => void
}

export default function CreateLearningPath({ onCreate }: CreateLearningPathProps) {
  const [open, setOpen] = useState(false)

  const handleCreateLearningPath = () => {
    setOpen(true)
  }

  return (
    <div className="flex w-full justify-end">
      <Button
        iconRight={<PlusIcon className="h-4 w-4" strokeWidth={3} />}
        size="lg"
        onClick={handleCreateLearningPath}
      >
        Crear materia
      </Button>
      <CreateLearningPathDialog open={open} setOpen={setOpen} onCreate={onCreate} />
    </div>
  )
}
