import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { ArrowRight } from "lucide-react"

interface ActivityCardProps {
  title: string
  description: string
  variant: "challenge" | "flashcard"
  handleOnClick: () => void
}

export default function ActivityDashboardCard({
  title,
  description,
  handleOnClick,
  variant,
}: ActivityCardProps) {
  return (
    <Card
      className="relative h-full w-full cursor-pointer overflow-hidden border-none bg-orange-100 shadow-inner before:absolute before:inset-0 before:z-10 before:bg-gradient-to-t before:from-orange-100/25 before:to-white/25 lg:h-[230px] lg:max-h-[264px]"
      onClick={handleOnClick}
    >
      <CardHeader className="pb-0">
        <CardTitle className="z-10 text-lg font-medium text-primary-600">{title}</CardTitle>
      </CardHeader>
      <CardContent className="relative z-20 flex justify-between">
        <span className="max-w-[30ch] text-sm text-muted-foreground max-[375px]:max-w-[20ch]">
          {description}
        </span>
        <div className="!size-10 self-end">
          <Button size="icon" className="rounded-lg bg-[#FDBA74] lg:hidden" onClick={handleOnClick}>
            <ArrowRight className="!h-6 !w-6 text-white" />
          </Button>
        </div>
      </CardContent>
      <img
        className="absolute bottom-0 right-0 -mb-1 w-32 lg:-mb-16 lg:w-44"
        src={
          variant === "challenge"
            ? "https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/challenge_card_image.png"
            : "https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/flashcards_card_image.png"
        }
      />
    </Card>
  )
}
