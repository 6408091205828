import { useNoLifeAlert } from "../stores/use-no-life-alert"
import { useUser } from "@/modules/user/stores"
import { useEffect, useState } from "react"
import GetPremiumAlert from "./get-premium-alert"

const getTimeUntilRefill = (refillLivesAt: string): string => {
  const now = new Date()
  const targetDate = new Date(refillLivesAt)
  const differenceInMs = targetDate.getTime() - now.getTime()
  const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60))

  if (differenceInMinutes >= 60) {
    const hours = Math.floor(differenceInMinutes / 60)
    return hours === 1 ? "1 hora" : `${hours} horas`
  } else if (differenceInMinutes === 0) {
    return "algunos segundos"
  } else {
    return differenceInMinutes === 1 ? "1 minuto" : `${differenceInMinutes} minutos`
  }
}

const NoLifeAlert = () => {
  const { refillLivesAt } = useUser().user
  const { isOpen, setIsOpen } = useNoLifeAlert()
  const [timeLeftMsg, setTimeLeftMsg] = useState("")
  const [differenceInMs, setDifferenceInMs] = useState(NaN)

  useEffect(() => {
    const updateRemainingTime = () => {
      const remainingTime = getTimeUntilRefill(refillLivesAt)
      setTimeLeftMsg(remainingTime)

      const newDifferenceInMs = new Date(refillLivesAt).getTime() - new Date().getTime()
      setDifferenceInMs(newDifferenceInMs)
    }

    updateRemainingTime()
    const interval = setInterval(updateRemainingTime, 30000)
    return () => clearInterval(interval)
  }, [refillLivesAt])

  const bodyComponent =
    !isNaN(differenceInMs) && differenceInMs <= 0 ? (
      <p>Ya se te regeneró una nueva vida. Recargá la página para poder iniciar el desafío.</p>
    ) : (
      <p>
        Esperá <span className="text-fuchsia-600">{timeLeftMsg}</span> o{" "}
        <span className="text-fuchsia-600">hacete Premium</span> y desbloqueá todo el potencial de
        Tich AI y cumplí tus objetivos académicos.
      </p>
    )

  return (
    <GetPremiumAlert
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      bodyComponent={bodyComponent}
      buttonText="Vidas ilimitadas"
    />
  )
}

export default NoLifeAlert
