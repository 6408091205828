import { Button } from "@/components/button"
import { useDownloadSignedDocument } from "@/modules/documents/queries/use-download-signed-document"
import { useUser } from "@/modules/user/stores"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import Layout from "./layout"
import { PDFDocument } from "pdf-lib"

const getPdfPageCount = async (pdfUrl: string) => {
  const existingPdfBytes = await fetch(pdfUrl).then((resp) => resp.arrayBuffer())
  const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true })
  return pdfDoc.getPageCount()
}

const NewChallengeByDocumentPage = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { mutateAsync, isError } = useDownloadSignedDocument()
  const { documents: userDocuments } = user
  const [pdfInfo, setPdfInfo] = useState({ name: "", id: "", link: "", totalPagesCount: 0 })
  const [isLoading, setIsLoading] = useState(false)

  const id = searchParams.get("id")
  const selectedDocument = userDocuments.find((doc) => doc.id === id)

  useEffect(() => {
    loadPdfInfo()
  }, [searchParams])

  if (!id || !selectedDocument) {
    navigate("/challenges/new")
    return
  }

  const loadPdfInfo = async () => {
    try {
      setIsLoading(true)
      const fileName = selectedDocument.filename ?? ""
      const documentId = selectedDocument.id
      const { data } = await mutateAsync({ documentId })
      const link = data.url ?? ""
      const totalPagesCount = await getPdfPageCount(link)
      setPdfInfo({ name: fileName, id: documentId, link, totalPagesCount })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="fixed left-0 top-0 z-50 size-full overflow-hidden bg-white">
      {isLoading ? (
        <div className="flex size-full items-center justify-center">
          <span>Cargando...</span>
        </div>
      ) : isError ? (
        <div className="flex size-full flex-col items-center justify-center gap-3">
          <span>Algo salió mal</span>
          <Button type="button" onClick={() => navigate("/challenges/new")}>
            Volver
          </Button>
        </div>
      ) : pdfInfo.id !== "" ? (
        <Layout pdfInfo={pdfInfo} />
      ) : null}
    </div>
  )
}

export default NewChallengeByDocumentPage
