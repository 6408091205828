import { client } from "@/config/client"

export enum CHALLENGE_TYPE {
  DOCUMENT = "document",
  INPUT = "input",
}

export type DocumentChallenge = {
  type: CHALLENGE_TYPE.DOCUMENT
  documentId: string
  fromPage: number
  toPage: number
  learningPathId: string
}

export type InputChallenge = {
  type: CHALLENGE_TYPE.INPUT
  input: string
  learningPathId: string
}

export type CreateChallengeData = DocumentChallenge | InputChallenge

const createChallenge = (data: CreateChallengeData) => {
  return client.post("/v1/challenges", data)
}

export default createChallenge
