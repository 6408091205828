import { client } from "@/config/client"
import type { Activity } from "../../activities/get"

export interface ProcessFlashcardsData {
  document: string
  startPage: string
  endPage: string
}

interface ProcessFlashcardsResponse extends Activity {}

export function process(data: ProcessFlashcardsData) {
  return client.post<ProcessFlashcardsResponse, ProcessFlashcardsData>(
    "/v1/documents/flashcards",
    data,
  )
}
