import type { StateCreator } from "zustand"
import { create } from "zustand"

interface State {
  isOpen: boolean
  challengeId: string
  openModal: (challengeIdToDelete: string) => void
  closeModal: () => void
}

const state: StateCreator<State> = (set) => ({
  isOpen: false,
  challengeId: "",
  openModal: (challengeIdToDelete: string) =>
    set({ isOpen: true, challengeId: challengeIdToDelete }),
  closeModal: () => set({ isOpen: false, challengeId: "" }),
})

export const useDeleteChallengeModal = create<State>(state)
export const { isOpen, challengeId, openModal, closeModal } = useDeleteChallengeModal.getState()
