import { api } from "@/services/api"
import { useQuery } from "@tanstack/react-query"

export default function useGetLpById(id: string) {
  const query = useQuery({
    queryKey: ["get-learning-path-by-id", id],
    queryFn: async () => api.learnignPaths.getById(id),
    select: (data) => data.data,
  })

  return query
}
