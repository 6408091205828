import { api } from "@/services/api"
import { useQuery } from "@tanstack/react-query"
import { useLearningPaths } from "../stores/use-lp-store"
import { useEffect } from "react"

export default function useGetLps(offset = 0, limit = 50) {
  const learnignPaths = useLearningPaths()
  const query = useQuery({
    queryKey: ["get-learning-paths", offset, limit],
    queryFn: async () => api.learnignPaths.get({ offset, limit }),
    select: (data) => data.data.learningPaths,
  })

  useEffect(() => {
    if (query.isSuccess) {
      learnignPaths.setLearningPaths(query.data)
    }
  }, [query.status, query.data])

  return query
}
