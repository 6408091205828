import { client } from "@/config/client"
import type { UserInfo } from "./info/get"

export interface UpdateProfileData {
  name: string
  interests: string
  movie?: string
  career?: string
  studentInstitution?: string
}

type UpdateProfileResponse = UserInfo

export function updateProfile(data: UpdateProfileData) {
  return client.put<UpdateProfileResponse, UpdateProfileData>(`/v1/users/profile`, data)
}
