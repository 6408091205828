import type { NotifiableError, Event, OnErrorCallback } from "@bugsnag/js"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import BugsnagPerformance from "@bugsnag/browser-performance"

interface BugSnagInitOptions {
  id: string
  email?: string
  name?: string
}

/**
 * Custom hook to integrate Bugsnag error tracker.
 *
 * @interface ClarityIdentifyInitOptions
 * @property {string} id - The unique identifier for the user.
 * @property {string} [email] - The email of the user.
 * @property {string} [name] - The name of the user.
 */
export default function useBugSnag() {
  const init = ({ id, email, name }: BugSnagInitOptions) => {
    Bugsnag.start({
      apiKey: "1803317dfc5947d2429f267b993345e7",
      plugins: [new BugsnagPluginReact()],
      user: {
        id,
        email,
        name,
      },
    })

    BugsnagPerformance.start({ apiKey: "1803317dfc5947d2429f267b993345e7" })
  }

  const notify = (
    error: unknown,
    onError?: OnErrorCallback,
    postReportCallback?: (err: Error, event: Event) => void,
  ) => {
    Bugsnag.notify(error as NotifiableError, onError, postReportCallback)
  }

  return {
    init,
    notify,
  }
}
