import React from "react"
import { Trash } from "lucide-react"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import type { ListSubtheme } from "@/services/api/learning-paths/update"
import useDeleteSubtheme from "@/modules/app/queries/use-delete-subtheme"

interface SubthemeProps {
  subtheme: ListSubtheme
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDelete: () => void
}

export default function Subtheme({ subtheme, onNameChange, onDelete }: SubthemeProps) {
  const { mutateAsync } = useDeleteSubtheme(subtheme.id ?? "")

  function handleDelete() {
    if (!subtheme.id) {
      onDelete()
      return
    }
    mutateAsync().then(onDelete)
  }

  return (
    <div className="flex items-center gap-2">
      <Input type="text" value={subtheme.name} onChange={onNameChange} className="flex-1" />
      <Button variant="ghost" onClick={handleDelete}>
        <Trash className="h-4 w-4" />
      </Button>
    </div>
  )
}
