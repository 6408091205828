import { client } from "@/config/client"

export interface LearningPathTheme {
  id: string
  name: string
  score?: number
  index?: number
  learningPathId: string
  createdAt: string
  updatedAt: string
}

export function get(learningPathId: string) {
  return client.get<LearningPathTheme[]>(`/v1/learning-paths/${learningPathId}/themes`)
}
