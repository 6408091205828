import { TbBell } from "react-icons/tb"
import UserMenu from "@/modules/app/components/user-menu"
import LearningPathsSelector from "@/modules/app/components/learning-path-selector"
import MobileLearningPathMenu from "@/modules/app/components/mobile-lp-menu"

export default function Header() {
  return (
    <header className="flex h-full w-full px-5 lg:p-10">
      <div className="hidden w-full items-center md:flex">
        <LearningPathsSelector />
      </div>
      <div className="flex w-full items-center md:hidden">
        <MobileLearningPathMenu />
      </div>
      <div className="flex w-full justify-end gap-12">
        <span className="flex items-center justify-between gap-4">
          <TbBell strokeWidth={2} className="size-7" />
          <UserMenu />
        </span>
      </div>
    </header>
  )
}
