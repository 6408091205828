import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { MoreHorizontal, Pencil, LogIn } from "lucide-react"
import { Button } from "@/components/ui/button"

interface UserActionsMenuProps {
  onEdit?: () => void
  onLoginAs?: () => void
}

export default function UserActionsMenu({ onEdit, onLoginAs }: UserActionsMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem className="cursor-pointer" onSelect={() => onEdit?.()}>
          <Pencil className="mr-2 h-4 w-4" />
          Editar
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer" onSelect={() => onLoginAs?.()}>
          <LogIn className="mr-2 h-4 w-4" />
          Ingresar
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
