import React, { useState } from "react"
import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { cn } from "@/lib/utils"
import {
  createPlanning,
  type CreatePlanningType,
} from "@/modules/learning-paths/validations/create-planning"
import { zodResolver } from "@hookform/resolvers/zod"
import { FileUp, Loader2, X } from "lucide-react"
import { useDropzone } from "react-dropzone"
import { useForm } from "react-hook-form"
import type { Document } from "@/services/api/documents/get"
import { useCreateDocument } from "@/modules/documents/queries/use-create"
import { usePresignedUrl } from "@/modules/documents/queries/use-create-presigned-url"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import { useUser } from "@/modules/user/stores"
import { useNavigate } from "react-router-dom"
import useCreateTheme from "@/modules/learning-paths/queries/use-create-theme"
import { toast } from "sonner"
import useBugSnag from "@/app/hooks/useBugSnag"

export default function CreatePlanningPage(): React.ReactNode {
  const [_, setFile] = useState<File | null>(null)
  const [selectedDoc, setSelectedDoc] = useState<Document | null>(null)
  const { selectedLearningPath } = useLearningPaths()
  const { user } = useUser()
  const createDocument = useCreateDocument()
  const presignedUrl = usePresignedUrl()
  const createTheme = useCreateTheme()
  const router = useNavigate()
  const bugsnag = useBugSnag()

  const uploadingDocument =
    createDocument.isPending || presignedUrl.create.isPending || presignedUrl.uploadFile.isPending

  const form = useForm<CreatePlanningType>({
    resolver: zodResolver(createPlanning),
    defaultValues: {
      start: 1,
      end: 40,
    },
  })

  function onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length <= 0) return
    setFile(acceptedFiles[0])
    handleFileUpload(acceptedFiles[0])
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
  })

  async function onCreateDocument(fileName: string, objectId: string) {
    try {
      const { data } = await createDocument.mutateAsync({
        file: fileName,
        learningPath: selectedLearningPath.id,
        userId: user.id,
        objectId,
      })
      setSelectedDoc(data)
    } catch (err) {
      bugsnag.notify(err)
    }
  }

  async function handleFileUpload(file: File) {
    if (!file) return
    try {
      const { data } = await presignedUrl.create.mutateAsync()
      await presignedUrl.uploadFile.mutateAsync({
        url: data.url,
        file,
      })
      await onCreateDocument(file.name, data.objectId)
    } catch (err) {
      bugsnag.notify(err)
    }
  }

  async function onSubmit(values: CreatePlanningType) {
    if (!selectedDoc) return
    try {
      await createTheme.mutateAsync({
        startPage: values.start,
        endPage: values.end,
        documentId: selectedDoc.id,
        learningPathId: selectedLearningPath.id,
      })
      router(`/learning-paths/edit/${selectedLearningPath.id}`)
    } catch {
      toast.error(
        "Ocurrió un error al crear la planificación. Por favor intentalo de nuevo más tarde",
      )
    }
  }

  function onRemoveDocument() {
    setSelectedDoc(null)
    setFile(null)
    form.reset()
  }

  return (
    <section className="space-y-10 p-12">
      <h2 className="text-2xl font-bold text-primary">Crear planificación</h2>
      <Form {...form}>
        <form className="space-y-3" onSubmit={form.handleSubmit(onSubmit)}>
          {!selectedDoc ? (
            <>
              <h3 className="font-medium">Cargar documento</h3>
              <section
                {...getRootProps()}
                className={cn(
                  "cursor-pointer rounded-lg border-2 border-dashed p-6 transition-colors",
                  isDragActive ? "border-primary" : "hover:bg-muted/50",
                )}
              >
                <input {...getInputProps()} />
                <section className="flex flex-col items-center justify-center text-center">
                  {uploadingDocument ? (
                    <Loader2 className="!size-6 animate-spin text-muted-foreground" />
                  ) : (
                    <>
                      <FileUp className="mb-3 h-8 w-8 text-muted-foreground" />
                      <p className="mb-2 text-sm text-muted-foreground">
                        {isDragActive
                          ? "Suelta el archivo aquí"
                          : "Arrastra y suelta tu PDF aquí o haz clic para seleccionar"}
                      </p>
                      <p className="text-sm font-medium text-primary">Seleccionar archivo</p>
                    </>
                  )}
                </section>
              </section>
            </>
          ) : (
            <div className="flex items-center justify-between rounded-lg bg-primary p-4 shadow">
              <span className="font-semibold text-white">{selectedDoc.filename}</span>
              <Button
                size="icon"
                variant="ghost"
                className="hover:bg-primary-300"
                onClick={onRemoveDocument}
              >
                <X className="!size-7 text-white" />
              </Button>
            </div>
          )}
          {selectedDoc && (
            <>
              <section className="grid grid-cols-1 gap-2 md:grid-cols-2">
                <div className="flex flex-col gap-1">
                  <Label htmlFor="start">Desde</Label>
                  <Input
                    {...form.register("start", { valueAsNumber: true })}
                    type="number"
                    placeholder="1"
                    disabled={uploadingDocument || createTheme.isPending}
                  />
                  {form.formState.errors.start && (
                    <p className="text-sm text-destructive">
                      {form.formState.errors.start.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <Label htmlFor="end">Hasta</Label>
                  <Input
                    {...form.register("end", { valueAsNumber: true })}
                    type="number"
                    placeholder="40"
                    disabled={uploadingDocument || createTheme.isPending}
                  />
                  {form.formState.errors.end && (
                    <p className="text-sm text-destructive">{form.formState.errors.end.message}</p>
                  )}
                </div>
              </section>
              {form.formState.errors.root ? (
                <p className="text-sm text-destructive">{form.formState.errors.root.message}</p>
              ) : (
                <p className="text-sm text-muted-foreground">
                  Debes elegir un rango máximo 40 páginas.
                </p>
              )}
            </>
          )}
          <Button type="submit" disabled={uploadingDocument}>
            {createTheme.isPending ? (
              <>
                <Loader2 className="animate-spin text-white" />
                Creando planificación...
              </>
            ) : (
              <span>Generar ruta de estudio</span>
            )}
          </Button>
        </form>
      </Form>
    </section>
  )
}
