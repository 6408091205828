import { type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "flashcards",
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: FlashcardsDashboardPage } = await import("@/pages/flashcards/dashboard")
        return { Component: FlashcardsDashboardPage }
      },
    },
    {
      path: "new",
      lazy: async () => {
        const { default: NewFlashcardPage } = await import("@/pages/flashcards/new")
        return { Component: NewFlashcardPage }
      },
      children: [
        {
          path: "document",
          lazy: async () => {
            const { default: NewFlashcardByDocumentPage } = await import(
              "@/pages/flashcards/new/document"
            )
            return { Component: NewFlashcardByDocumentPage }
          },
        },
      ],
    },
    {
      path: ":flashcardId",
      lazy: async () => {
        const { default: FlashcardGamePage } = await import("@/pages/flashcards/game")
        return { Component: FlashcardGamePage }
      },
    },
  ],
}

export default routes
