import { Button } from "@/components/ui/button"
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer"
import usePlanModal from "@/modules/plans/stores/use-plan-modal"
import { Crown } from "lucide-react"
import { type ReactElement } from "react"
import { useNavigate } from "react-router-dom"

export interface GetPremiumAlertProps {
  bodyComponent: ReactElement
  buttonText: string
  isOpen: boolean
  setIsOpen: (newState: boolean) => void
}

export default function GetPremiumAlert(props: GetPremiumAlertProps) {
  const navigate = useNavigate()
  const { setOpen: setOpenPlan } = usePlanModal()
  function handleTestPremium() {
    props.setIsOpen(false)
    navigate("/dashboard")
    setOpenPlan()
  }
  return (
    <Drawer open={props.isOpen} onOpenChange={() => props.setIsOpen(!props.isOpen)}>
      <DrawerContent
        className={`mx-auto w-full max-w-[500px] overflow-hidden border-none bg-white p-3 pt-8 shadow-[0px_24px_60px_0px_rgba(0,0,0,0.25)] [&>*:first-child]:hidden`}
      >
        <DrawerHeader className="p-0">
          <DrawerTitle className="mx-auto max-w-[290px] p-0 text-center text-[16px] font-bold text-gray-600">
            {props.bodyComponent}
          </DrawerTitle>
        </DrawerHeader>
        <DrawerFooter className="mt-6 w-full p-0">
          <Button
            type="button"
            onClick={handleTestPremium}
            className="flex w-full items-center justify-between rounded-[8px] bg-fuchsia-700 text-sm text-white transition-colors duration-150 hover:bg-fuchsia-800"
          >
            <span>{props.buttonText}</span>
            <span className="flex items-center justify-center gap-2">
              Probar Premium <Crown />
            </span>
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
