import { api } from "@/services/api"
import { useMutation } from "@tanstack/react-query"

export default function useDeleteLpById(id: string) {
  const query = useMutation({
    mutationKey: ["delete-learnign-path-by-id", id],
    mutationFn: async () => api.learnignPaths.deleteById(id),
  })

  return query
}
