import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { Button } from "@/components/button"
import { Input } from "@/components/ui/input"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import useLoginWithEmail from "@/modules/auth/queries/use-login-with-email"
import { useState } from "react"

const loginSchema = z.object({
  email: z.string().email({ message: "Correo electrónico inválido" }),
  password: z.string().min(3, { message: "La contraseña debe tener al menos 3 caracteres" }),
})

type LoginFormData = z.infer<typeof loginSchema>

export default function EmailLoginForm() {
  const [error, setError] = useState("")
  const loginWithEmail = useLoginWithEmail()

  const form = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const handleOnSubmit = async () => {
    const values = form.getValues()
    try {
      await loginWithEmail.mutateAsync(values)
    } catch {
      setError("Credenciales inválidas")
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleOnSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Correo Electrónico</FormLabel>
              <FormControl>
                <Input placeholder="tucorreo@ejemplo.com" type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contraseña</FormLabel>
              <FormControl>
                <Input placeholder="Ingresa tu contraseña" type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormMessage>{error}</FormMessage>
        <Button type="submit" className="w-full" loading={loginWithEmail.isPending}>
          Iniciar Sesión
        </Button>
      </form>
    </Form>
  )
}
