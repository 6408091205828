import { useNavigate } from "react-router-dom"

export interface NavItemProps {
  icon: React.ElementType
  label: string
  link: string
  disabled: boolean
}

export default function NavItem({ icon: Icon, link, disabled, label }: NavItemProps) {
  const navigate = useNavigate()

  const handleOnClickItem = () => {
    if (disabled) return
    const isExternalLink = link.startsWith("http")

    if (isExternalLink) {
      window.open(link, "_blank")
    } else {
      navigate(link)
    }
  }

  return (
    <div
      style={{ display: disabled ? "none" : "flex" }}
      className="w-full cursor-pointer items-center justify-center rounded-lg p-3 hover:bg-gray-100"
      onClick={handleOnClickItem}
      title={label}
    >
      <Icon className="h-6 w-6 text-secondary" />
    </div>
  )
}
