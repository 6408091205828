import { Navigate } from "react-router-dom"
import { useAuth } from "../stores"

export interface ProtectedRouteProps {
  redirect: string
  isAuth?: boolean
  children: React.ReactNode
}

export default function ProtectedRoute({
  redirect,
  isAuth = false,
  children,
}: ProtectedRouteProps) {
  const { isAuthenticated } = useAuth()
  // redirect if authentication is not required and user is not authenticated
  if (isAuth && !isAuthenticated) {
    return <Navigate to={redirect} replace />
  }

  // redirect if authentication is required and user is authenticated
  if (!isAuth && isAuthenticated) {
    return <Navigate to={redirect} replace />
  }

  return <>{children}</>
}
