import { z } from "zod"

export const createSessionSchema = z
  .object({
    startPage: z.number().min(1, "El número debe ser mayor a 0").default(1),
    endPage: z.number().min(1, "El número debe ser mayor a 0").default(40),
  })
  .refine((data) => !isNaN(data.startPage) && !isNaN(data.endPage), {
    message: "Los valores deben ser números válidos",
    path: ["root"],
  })
  .refine((data) => data.endPage > data.startPage, {
    message: "La página final debe ser mayor que la página inicial",
    path: ["end"],
  })
  .refine((data) => data.endPage - data.startPage <= 40, {
    message: "Debes elegir un máximo 40 páginas",
    path: ["root"],
  })

export type CreateSessionSchemaType = z.infer<typeof createSessionSchema>
