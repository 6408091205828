import { z } from "zod"

export const createPlanning = z
  .object({
    start: z.number().min(1, "El número debe ser mayor a 0").default(1),
    end: z.number().min(1, "El número debe ser mayor a 0").default(40),
  })
  .refine((data) => data.end > data.start, {
    message: "La página final debe ser mayor que la página inicial",
    path: ["end"],
  })
  .refine((data) => data.end - data.start <= 40, {
    message: "Debes elegir un máximo 40 páginas",
    path: ["root"],
  })

export type CreatePlanningType = z.infer<typeof createPlanning>
