import createChallenge from "./create-challenge"
import getUserChallenges from "./get-user-challenges"
import createLevelSession from "./create-level-session"
import answerSessionQuestion from "./answer-session-question"
import getSessionReview from "./get-session-review"
import deleteChallenge from "./delete-challenge"
import editChallenge from "./edit-challenge"

const ChallengesAPI = {
  createChallenge,
  getUserChallenges,
  createLevelSession,
  answerSessionQuestion,
  getSessionReview,
  deleteChallenge,
  editChallenge,
}

export default ChallengesAPI
