import { client } from "@/config/client"

interface DownloadSignedData {
  documentId: string
}
interface DownloadSignedResponse {
  url: string
}

export function downloadSigned(data: DownloadSignedData) {
  return client.post<DownloadSignedResponse, DownloadSignedData>(
    "/v1/documents/download/signed",
    data,
  )
}
