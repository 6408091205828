import { useEffect, useState } from "react"
import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTrigger } from "@/components/ui/sheet"
import { Crown, Menu, Plus, X } from "lucide-react"
import { useUser } from "@/modules/user/stores"
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"
import { Button } from "@/components/ui/button"
import { useLearningPaths } from "../stores/use-lp-store"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { useMediaQuery } from "usehooks-ts"
import usePlanModal from "@/modules/plans/stores/use-plan-modal"
import { cn } from "@/lib/utils"
import { getRandomTailwindBg } from "@/utils"
import useGetLps from "../queries/use-get-lps"
import CreateLearningPathDialog from "@/modules/learning-paths/components/create/create-learning-path-modal"

export default function MobileLearningPathMenu() {
  const [open, setOpen] = useState(false)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const { user } = useUser()
  const { learningPaths, setSelectedLearningPath } = useLearningPaths()
  const isMdSize = useMediaQuery("(min-width: 768px)")
  const { setOpen: setOpenPlanModal } = usePlanModal()
  const { refetch } = useGetLps()

  const sortedLearningPaths = [...learningPaths].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )

  function handleValueChange(id: string) {
    const result = learningPaths.find((path) => path.id === id)
    if (result) {
      setSelectedLearningPath(result)
    }
    setOpen(false)
  }

  function onOpenCreateLpModal() {
    setOpen(false)
    setOpenCreateModal(true)
  }

  function onCreate() {
    refetch()
  }

  function handleTestPremium() {
    setOpenPlanModal()
    setOpen(false)
  }

  useEffect(() => {
    const initialSubject = learningPaths.at(-1)

    if (initialSubject) {
      setSelectedLearningPath(initialSubject)
    }
  }, [])

  useEffect(() => {
    if (isMdSize) {
      setOpen(false)
    }
  }, [isMdSize])

  return (
    <>
      <Sheet open={open} onOpenChange={() => setOpen(!open)}>
        <SheetTrigger>
          <Menu />
        </SheetTrigger>
        <SheetContent side="top" withClose={false} className="h-fit rounded-b-3xl pb-0">
          <SheetHeader>
            <SheetClose onClick={() => setOpen(false)}>
              <X className="!size-9" />
            </SheetClose>
          </SheetHeader>
          <section className="flex flex-col gap-4 py-4">
            <h2 className="text-lg font-semibold text-black/85">Materias</h2>
            <ScrollArea className="w-full whitespace-nowrap">
              <section className="mb-2 flex gap-2">
                <div
                  onClick={onOpenCreateLpModal}
                  className="flex w-24 cursor-pointer flex-col items-center gap-3"
                >
                  <Button size="icon" className="rounded-full bg-[#FED7AA] text-primary">
                    <Plus className="!size-8" />
                  </Button>
                  <p className="text-base font-medium text-primary">Nueva</p>
                </div>
                {sortedLearningPaths.map((path) => (
                  <div
                    className="flex w-24 cursor-pointer flex-col items-center gap-3"
                    key={path.id}
                    onClick={() => handleValueChange(path.id)}
                  >
                    <Avatar>
                      <AvatarFallback
                        className={cn(
                          "font-semibold uppercase text-black/30",
                          getRandomTailwindBg(),
                        )}
                      >
                        {path.name.charAt(0) + path.name.charAt(1)}
                      </AvatarFallback>
                    </Avatar>
                    <p className="w-[8ch] overflow-hidden text-ellipsis text-center text-base">
                      {path.name}
                    </p>
                  </div>
                ))}
              </section>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </section>
          {user.plan !== "PRO" && (
            <section className="flex flex-col gap-4 py-4">
              <h2 className="text-lg font-semibold text-black/85">Mi plan</h2>
              <Button
                size="lg"
                className="flex w-full justify-between gap-2 rounded-2xl bg-fuchsia-700 px-4 py-4 text-left shadow-md hover:bg-fuchsia-800"
                onClick={handleTestPremium}
              >
                <Crown className="!size-5" />
                Probar Premium
              </Button>
            </section>
          )}
        </SheetContent>
      </Sheet>
      <CreateLearningPathDialog
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        onCreate={onCreate}
      />
    </>
  )
}
