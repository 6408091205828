import { EllipsisVertical } from "lucide-react"
import type { Challenge } from "@/services/api/challenges/get-user-challenges"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useDeleteChallengeModal } from "../../stores/use-delete-challenge-modal-store"
import { useEditChallengeModal } from "../../stores/use-edit-challenge-modal-store"

interface ChallengeItemMenuProps {
  challenge: Challenge
}

const ChallengeItemMenu = ({ challenge }: ChallengeItemMenuProps) => {
  const { openModal: openDeleteChallengeModal } = useDeleteChallengeModal()
  const { openModal: openEditChallengeModal } = useEditChallengeModal()

  const handleOnRename = () => {
    openEditChallengeModal(challenge)
  }

  const handleOnDelete = () => {
    openDeleteChallengeModal(challenge.id)
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <EllipsisVertical className="h-7 w-6 rounded-[8px] text-gray-500 hover:bg-neutral-200" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={handleOnRename} className="text-sm font-normal">
          Renombrar
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleOnDelete} className="text-sm font-normal text-red-600">
          Eliminar
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ChallengeItemMenu
