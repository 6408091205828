import { useState } from "react"
import { request } from "../features/chatRequest"
import { getMessage } from "../features/chatMessage"

export type Message = {
  role: "user" | "assistant"
  content: string
}

export type Data<P> = {
  headers?: HeadersInit
  messages?: Message[]
  payload: P
  api: string
  baseUrl?: string
}

export const useChat = <P>(data: Data<P>) => {
  const [value, setValue] = useState<string>("")
  const [messages, setMessages] = useState<Message[]>(data.messages || [])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const send = async (text: string) => {
    const userMessage: Message = { role: "user", content: text }
    setMessages((prevMessages) => [...prevMessages, userMessage])
    setIsLoading(true)
    setIsError(false)

    try {
      const url = data.baseUrl
        ? data.baseUrl + data.api
        : import.meta.env.VITE_BASE_API_URL + data.api

      const res = await request(url, data.headers, {
        ...data.payload,
        messages: [...messages, userMessage],
      })

      if (!res.ok) {
        throw new Error("Error al obtener la respuesta del servidor")
      }

      if (!res.body) {
        throw new Error("Response body is empty")
      }

      const reader = res.body.getReader()
      const content = await getMessage(reader)
      const assistantMessage: Message = {
        role: "assistant",
        content,
      }

      setMessages((prevMessages) => [...prevMessages, assistantMessage])
    } catch {
      setIsError(true)
      setMessages((prevMessages) => prevMessages.slice(0, -1))
    } finally {
      setIsLoading(false)
    }
  }

  const retry = () => {
    if (messages.length > 0) {
      const lastUserMessage = [...messages].reverse().find((m) => m.role === "user")
      if (lastUserMessage) {
        send(lastUserMessage.content)
      }
    }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValue(e.target.value)
  }

  const handleOnSubmit = () => {
    send(value)
    setValue("")
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.code === "Enter") {
      e.preventDefault()
      handleOnSubmit()
    }
  }

  return {
    value,
    messages,
    isError,
    isLoading,
    send,
    retry,
    handleOnChange,
    handleOnSubmit,
    handleKeyDown,
  }
}
