import { useState } from "react"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import EmailLoginForm from "@/modules/login/components/email-form"
import GoogleLoginButton from "@/modules/login/components/google-login-button"
import useGoogleAuthUrl from "@/modules/login/hooks/use-auth-url"
import isProd from "@/utils/is-prod"

export default function LoginPage() {
  const [showEmailLogin, setShowEmailLogin] = useState(false)

  const url = useGoogleAuthUrl({
    clientId: import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID,
    redirectUri: import.meta.env.VITE_GOOGLE_AUTH_REDIRECT_URI,
  })

  const handleGoogleLogin = () => {
    window.location.href = url
  }

  return (
    <div className="flex min-h-screen w-full items-center justify-center shadow-lg">
      <Card className="w-full max-w-md space-y-6 p-12">
        <CardHeader className="text-center">
          <CardTitle className="flex gap-2 text-3xl font-bold">
            <img
              width={42}
              height={42}
              src="https://storage.googleapis.com/educabot-aec-cdn-1/tich-landing/images/hat_logo.svg"
              alt=""
            />
            Bienvenido a Tich
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          {!showEmailLogin ? (
            <>
              <GoogleLoginButton onClick={handleGoogleLogin} />
              {!isProd && (
                <div className="text-center">
                  <Button
                    variant="link"
                    className="text-primary"
                    onClick={() => setShowEmailLogin(true)}
                  >
                    Continuar con Email
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <Button
                variant="link"
                className="-ml-4 mb-4"
                onClick={() => setShowEmailLogin(false)}
              >
                Volver
              </Button>
              <EmailLoginForm />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  )
}
