import { client } from "@/config/client"
import type { QUESTION_TYPE } from "../learning-paths/themes/subthemes/get"

export interface LearningSessionAnswer {
  index: number
  answer: string
  feedback?: string
  score?: number
  correct?: boolean
}

export interface CreateFeedbackData {
  questionType: QUESTION_TYPE
  userAnswer: string
  answer: string
  question: string
}

export interface CreateFeedbackResponse {
  data: {
    feedback: string
    score: number
  }
}

export function createFeedback(data: CreateFeedbackData) {
  return client.post<string, CreateFeedbackData>("/v1/diagnoses/feedback", data)
}
