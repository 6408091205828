import getSessionReview, {
  type GetSessionReviewParams,
} from "@/services/api/challenges/get-session-review"

import { useQuery } from "@tanstack/react-query"

const useGetSessionReviewQuery = (params: GetSessionReviewParams) => {
  const query = useQuery({
    queryKey: ["sessionReview", params],
    queryFn: () => getSessionReview(params),
  })

  return query
}

export default useGetSessionReviewQuery
