import { ArrowUpRight } from "lucide-react"
import { useAuth } from "@/modules/auth/stores"
import { useChat } from "@/modules/chat/hooks/useChat"
import { useEffect, useRef } from "react"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Loader } from "@/components/loader"
import { useUser } from "@/modules/user/stores"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import { Avatar, AvatarImage } from "@/components/ui/avatar"
import TitoButton from "./tito-button"
import snakecase from "snakecase-keys"

export default function ChatTito() {
  const { user } = useUser()
  const { token } = useAuth()
  const { selectedLearningPath } = useLearningPaths()
  const endRef = useRef<HTMLDivElement>(null)
  const { value, messages, isLoading, handleOnChange, handleKeyDown, handleOnSubmit } = useChat({
    api: "/v1/tutors/chat/tito",
    payload: snakecase(
      {
        user: {
          name: user.name,
          country: user.country,
          interests: user.interests?.split(",") || [],
        },
        learningPath: selectedLearningPath.name,
      },
      { deep: true },
    ),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    messages: [
      {
        role: "assistant",
        content: `Soy Tito 👋\n\nPodés consultarme sobre:\n\n• Organización\n• Métodos de estudio\n• Dudas sobre el contenido de tus materias.`,
      },
    ],
  })

  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [messages])

  return (
    <div className="flex h-full w-full flex-col justify-between px-6 py-4">
      <ScrollArea className="h-[540px] p-6 pl-0">
        <div className="flex flex-grow flex-col space-y-6">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex gap-3 ${message.role === "user" ? "flex-row-reverse" : ""}`}
            >
              {message.role === "assistant" ? (
                <TitoButton className="h-8 w-8 shadow-md" iconClassName="!size-5" />
              ) : (
                <div className="flex h-fit w-fit items-center justify-center rounded-full">
                  <Avatar className="size-8 cursor-pointer">
                    <AvatarImage src={user.avatar} className="h-full w-full object-cover" />
                  </Avatar>
                </div>
              )}
              <div
                className={`max-w-[80%] rounded-xl p-4 ${
                  message.role === "assistant"
                    ? "border border-orange-100 bg-orange-50"
                    : "border-gray-100 bg-gray-100"
                }`}
                style={{ whiteSpace: "pre-line", lineHeight: "1.2" }}
              >
                {message.content}
              </div>
            </div>
          ))}
          <div ref={endRef} />
        </div>
      </ScrollArea>
      <form className="flex h-[55px] w-full">
        <div className="flex w-full rounded-lg border border-gray-300 bg-gray-50 p-1">
          <input
            type="text"
            className="flex-1 bg-transparent px-4 outline-none"
            placeholder="Escribí un mensaje..."
            value={value}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            disabled={isLoading}
          />
          <button
            type="submit"
            className="flex items-center justify-center rounded-lg bg-black p-3"
            disabled={isLoading}
            onClick={handleOnSubmit}
          >
            {isLoading ? (
              <Loader color="white" size="xs" />
            ) : (
              <ArrowUpRight strokeWidth={3} className="h-4 w-5 font-bold text-white" />
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
