import { loadStripe } from "@stripe/stripe-js"
import { useUser } from "@/modules/user/stores"
import { useCreateStripeSession } from "@/modules/plans/queries"
import isProd from "@/utils/is-prod"
import { Button } from "@/components/button"
import { useEffect, useState } from "react"
import type { Plan } from "../types/plan"
import { PlanCard } from "./plan-card"

export default function PlanSelectionStep() {
  const { user } = useUser()
  const [loading, setLoading] = useState(false)
  const createStripeSession = useCreateStripeSession()
  const [monthlyPlansToggled, setMonthlyPlansToggled] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<Plan>(plans[0])
  const usdToArs = 1536
  const plansToShow = monthlyPlansToggled ? plans : plans.filter((p) => p.periodInMonths === 12)

  useEffect(() => {
    setLoading(false)
  }, [])

  async function handleGoToStripe() {
    try {
      setLoading(true)
      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)
      const data = await createStripeSession.mutateAsync(selectedPlan.stripeId)
      await stripe?.redirectToCheckout({ sessionId: data.data.sessionId })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="mx-auto max-w-2xl px-3 py-4 sm:px-4 sm:py-6">
      <div className="space-y-2 sm:space-y-3">
        {plansToShow.map((plan, index) => (
          <PlanCard
            key={index}
            plan={plan}
            selected={selectedPlan?.name === plan.name}
            onSelect={() => setSelectedPlan(plan)}
            userCountry={user.country}
            usdToArs={usdToArs}
          />
        ))}
        <Button
          variant="ghost"
          className="w-full justify-center text-xs font-medium text-gray-600 hover:text-orange-600 sm:text-sm"
          onClick={() => setMonthlyPlansToggled(!monthlyPlansToggled)}
        >
          {monthlyPlansToggled ? "Ver sólo planes anuales" : "Ver todos los planes"}
        </Button>
      </div>

      <div className="mt-4 space-y-4 sm:mt-6 sm:space-y-6">
        <Button
          loading={loading}
          className="w-full rounded-xl bg-orange-500 py-2 text-sm font-semibold text-white hover:bg-orange-600 sm:py-3 sm:text-base"
          disabled={!selectedPlan}
          onClick={handleGoToStripe}
        >
          Comenzar ahora
        </Button>
        <p className="text-center text-xs text-gray-500 sm:text-xs">
          <span className="mb-0.5 block font-medium sm:mb-1">
            Puedes cancelarla en cualquier momento sin costos adicionales.
          </span>
          <span className="text-gray-400">
            Tu suscripción se renovará automáticamente por el mismo período de tiempo a menos que
            sea cancelada al menos 24 horas antes de que termine el período actual.
          </span>
        </p>
        <div className="flex justify-center">
          <a
            href="https://wa.me/+543794232487"
            target="_blank"
            className="inline-flex items-center rounded-full bg-blue-50 px-3 py-1.5 text-sm font-medium text-blue-500 hover:bg-blue-100 sm:px-4 sm:py-2 sm:text-xs"
          >
            Tuve un problema con mi pago
          </a>
        </div>
      </div>
    </div>
  )
}

const plans: Plan[] = [
  {
    id: 1,
    name: "Plan Amigos Anual",
    description: "12 meses - US$ 5,84/MES",
    priceInUsd: 69,
    periodInMonths: 12,
    students: 2,
    stripeId: isProd ? "price_1PDGGwJvm5wiWXqfw6nfGbpT" : "price_1Png3lJvm5wiWXqfL1PJ5hX9",
  },
  {
    id: 2,
    name: "Plan Individual Anual",
    description: "12 meses - US$ 4,17/MES",
    priceInUsd: 49.99,
    periodInMonths: 12,
    students: 1,
    stripeId: isProd ? "price_1PDFK7Jvm5wiWXqfhtfhxLOz" : "price_1Png3lJvm5wiWXqfL1PJ5hX9",
  },
  {
    id: 3,
    name: "Plan Individual Mensual",
    description: "1 mes - US$ 5,99/MES",
    priceInUsd: 5.99,
    periodInMonths: 1,
    students: 1,
    stripeId: isProd ? "price_1PDFMSJvm5wiWXqfqpUeJ29t" : "price_1Png3lJvm5wiWXqfL1PJ5hX9",
  },
]
