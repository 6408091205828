import { useLocation, useNavigate } from "react-router-dom"
import { X } from "lucide-react"
import useGetSessionReviewQuery from "@/modules/challenges/queries/use-get-session-review-query"
import ReviewLayout from "@/modules/challenges/features/review/review-layout"
import { Button } from "@/components/button"

const Review = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const levelId = location.state.levelId
  const lastCompletedSessionId = location.state.lastCompletedSessionId

  const { isLoading, data, isError } = useGetSessionReviewQuery({
    levelId: levelId,
    sessionId: lastCompletedSessionId,
  })

  const handleOnClose = () => {
    navigate("/challenges")
  }

  return (
    <div className="fixed left-0 top-0 h-full w-full overflow-hidden bg-amber-50">
      {/* Header. */}
      <div className="flex w-full shrink-0 items-center justify-center">
        <p className="w-full pl-14 text-center text-lg font-semibold">Resumen</p>

        <button type="button" className="shrink-0 p-4" onClick={handleOnClose}>
          <X className="text-gray-500" />
        </button>
      </div>

      {/* Content. */}
      <div className="flex h-full w-full items-start justify-center overflow-y-auto pb-[56px]">
        {isLoading ? (
          <p className="m-auto">Cargando...</p>
        ) : isError ? (
          <p className="m-auto flex h-full w-full flex-col items-center justify-center gap-3">
            <span>Algo salió mal.</span>
            <Button type="button" onClick={() => navigate("/challenges")}>
              Volver
            </Button>
          </p>
        ) : data ? (
          <ReviewLayout review={data.review} currentLevelId={levelId} />
        ) : null}
      </div>
    </div>
  )
}

export default Review
