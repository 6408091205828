import React from "react"

interface CircularProgressProps {
  progress: number
  circleSize?: number
  color?: string
  withoutPercentage?: boolean
  className?: string
}

const CircularProgress: React.FC<CircularProgressProps> = ({
  progress,
  circleSize = 40,
  color = "orange",
  withoutPercentage = false,
  className,
}) => {
  const radius = circleSize / 2 - 4
  const circumference = 2 * Math.PI * radius
  const strokeDashoffset = circumference - (progress / 100) * circumference

  return (
    <div className={`relative inline-block ${className || ""}`}>
      <svg
        className="-rotate-90 transform"
        width={circleSize}
        height={circleSize}
        viewBox={`0 0 ${circleSize} ${circleSize}`}
      >
        <circle
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={radius}
          fill="transparent"
          stroke="#e5e7eb"
          strokeWidth={4}
        />
        <circle
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={radius}
          fill="transparent"
          stroke={color}
          strokeWidth={4}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          style={{ transition: "stroke-dashoffset 0.35s" }}
        />
      </svg>
      {!withoutPercentage && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform font-medium text-gray-700">
          {Math.round(progress)}%
        </div>
      )}
    </div>
  )
}

export default CircularProgress
