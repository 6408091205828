import ChallengesDashboard from "@/pages/challenges/dashboard"
import Game from "@/pages/challenges/game"
import NewChallengePage from "@/pages/challenges/newChallenge"
import NewChallengeByDocumentPage from "@/pages/challenges/newChallenge/newChallengeByDocument"
import Review from "@/pages/challenges/review"
import { Navigate, Outlet, type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "challenges",
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <ChallengesDashboard />,
    },
    {
      path: "review",
      element: <Review />,
    },
    {
      path: ":levelId",
      element: <Game />,
    },
    {
      path: "new",
      element: <Outlet />,
      children: [
        {
          path: "document",
          element: <NewChallengeByDocumentPage />,
        },
        {
          index: true,
          element: <NewChallengePage />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="challenges" replace />,
    },
  ],
}

export default routes
