const getDifferenceInSeconds = (
  initialTimeInMiliseconds: number,
  endTimeInMiliseconds: number,
): number => {
  const differenceInMiliseconds = endTimeInMiliseconds - initialTimeInMiliseconds
  const differenceInSeconds = Math.floor(differenceInMiliseconds / 1000)
  return differenceInSeconds
}

export default getDifferenceInSeconds
