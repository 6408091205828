import { api } from "@/services/api"
import { useQuery } from "@tanstack/react-query"

export default function useAdminMetrics() {
  const query = useQuery({
    queryKey: ["admin-metrics"],
    queryFn: async () => api.admin.metrics(),
    select: (data) => data.data,
  })

  return query
}
