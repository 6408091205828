import type { SessionReview } from "@/services/api/diagnoses/create-review"
import { useSessionReview } from "../../stores/use-session-review"
import { Button } from "@/components/ui/button"
import { ArrowDown, ArrowUp } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import CircularProgress from "@/components/progress/circular-progress"
import { useLearningSession } from "../../stores/use-learning-session"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"

interface SessionReviewProps {
  score: number
  redirect?: string
}

export function SessionReview({ score, redirect }: SessionReviewProps) {
  const { selectedTheme } = useLearningPaths()
  const { sessionReview, setSessionReview } = useSessionReview()
  const { clearState } = useLearningSession()
  const router = useNavigate()
  const { negative, positive, strategies } = sessionReview as SessionReview

  async function handleLearningReview() {
    if (redirect) {
      router(redirect)
    }
    router("/diagnostico")
    setSessionReview(null)
    clearState()
  }

  return (
    <section>
      <section className="mx-auto max-w-4xl space-y-6">
        <h2 className="text-center text-2xl font-bold text-primary">{selectedTheme.name}</h2>
        <section className="flex flex-col items-stretch gap-6 md:flex-row">
          <Card className="flex w-full flex-col md:w-[300px]">
            <CardHeader className="pb-2 text-center">
              <CardTitle className="text-2xl font-bold">Tu Puntaje</CardTitle>
            </CardHeader>
            <CardContent className="flex items-center justify-center p-6">
              <section className="w-48">
                <CircularProgress className="size-48" progress={score} circleSize={45} />
              </section>
            </CardContent>
          </Card>

          <section className="flex flex-1 flex-col gap-6">
            <Card>
              <CardHeader className="pb-2">
                <section className="flex items-center gap-2 text-green-600">
                  <ArrowUp className="h-5 w-5" />
                  <CardTitle>Puntos positivos</CardTitle>
                </section>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600">{positive}</p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="pb-2">
                <section className="flex items-center gap-2 text-red-600">
                  <ArrowDown className="h-5 w-5" />
                  <CardTitle>Puntos negativos</CardTitle>
                </section>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600">{negative}</p>
              </CardContent>
            </Card>
          </section>
        </section>

        <Card>
          <CardHeader>
            <CardTitle className="text-2xl">Estrategias de mejora</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            {strategies.map((strategy, index) => (
              <section key={strategy} className="flex flex-col">
                <section className="flex items-start gap-4">
                  <section className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-primary-50 text-primary">
                    {index + 1}
                  </section>
                  <p className="flex-1 text-gray-600">{strategy}</p>
                </section>
                {index < strategies.length - 1 && (
                  <div className="my-4 h-px w-[90%] self-center bg-slate-200" />
                )}
              </section>
            ))}
          </CardContent>
        </Card>

        <section className="flex justify-center">
          <Button size="lg" onClick={handleLearningReview}>
            Terminar sesión
          </Button>
        </section>
      </section>
    </section>
  )
}
