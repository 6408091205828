import type { StateCreator } from "zustand"
import { create } from "zustand"
import type { Challenge } from "@/services/api/challenges/get-user-challenges"

interface State {
  isOpen: boolean
  challengeToEdit: Challenge | null
  openModal: (challenge: Challenge) => void
  closeModal: () => void
}

const state: StateCreator<State> = (set) => ({
  isOpen: false,
  challengeToEdit: null,
  openModal: (challenge: Challenge) => set({ isOpen: true, challengeToEdit: challenge }),
  closeModal: () => set({ isOpen: false, challengeToEdit: null }),
})

export const useEditChallengeModal = create<State>(state)
export const { isOpen, challengeToEdit, openModal, closeModal } = useEditChallengeModal.getState()
