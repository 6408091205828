import { api } from "@/services/api"
import type { UpdateData } from "@/services/api/learning-paths/update"
import { useMutation } from "@tanstack/react-query"

export default function useUpdateLp(id: string) {
  const query = useMutation({
    mutationKey: ["update-learning-path", id],
    mutationFn: async (payload: UpdateData) => api.learnignPaths.update(id, payload),
  })

  return query
}
