import { ChevronRight } from "lucide-react"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { useState } from "react"
import type { Challenge } from "@/services/api/challenges/get-user-challenges"
import ChallengeLevelsMap from "./challenge-levels-map"
import ChallengeItemMenu from "./challenge-item-menu"

interface CollapsableChallengeListItemProps {
  challenge: Challenge
}

const CollapsableChallengeListItem = ({ challenge }: CollapsableChallengeListItemProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen}>
      <div className="flex w-full items-start justify-between gap-4 rounded-[8px] p-2 transition duration-300 hover:bg-neutral-100">
        <CollapsibleTrigger className="flex w-full items-center justify-between">
          <div className="flex items-start justify-center gap-3">
            <ChevronRight
              className={`text-gray-500 transition-transform duration-200 ${isOpen ? "rotate-90" : "rotate-0"}`}
            />

            <div className="flex flex-col items-start">
              <p className="w-[28ch] break-words text-left text-sm font-bold">{challenge.name}</p>
              <span className="text-xs font-normal text-gray-400">
                Niveles: {challenge.levels.length}
              </span>
            </div>
          </div>

          {/* <div className="flex items-center justify-center gap-1">
            <GraduationCap className="text-tich-primary" />
            <span className="text-tich-primary text-sm font-bold">{120}</span>
          </div> */}
        </CollapsibleTrigger>

        <ChallengeItemMenu challenge={challenge} />
      </div>

      <CollapsibleContent className="mt-4 w-full rounded-xl bg-neutral-100 p-3">
        <ChallengeLevelsMap levels={challenge.levels} />
      </CollapsibleContent>
    </Collapsible>
  )
}

export default CollapsableChallengeListItem
