import { useState } from "react"
import { Card } from "@/components/ui/card"
import { Button } from "@/components/button"
import { Edit, Eye, Trash2 } from "lucide-react"
import { useNavigate } from "react-router-dom"
import type { LearningPath } from "@/services/api/learning-paths/get"
import DeleteLearningPathDialog from "./delete/delete-learning-path-modal"

interface LearningPathProps {
  learningPath: LearningPath
  onDelete: () => void
}

export default function LearningPath({ learningPath, onDelete }: LearningPathProps) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const handleView = () => {
    navigate(`/learning-paths/${learningPath.id}`)
  }

  const handleEdit = () => {
    navigate(`/learning-paths/edit/${learningPath.id}`)
  }

  const handleDelete = () => {
    setOpen(true)
  }

  const hadnleOnOpenChange = (value: boolean) => {
    setOpen(value)
  }

  return (
    <Card className="flex items-center justify-between rounded-lg border border-solid px-6 py-5">
      <p className="text-md font-medium">{learningPath.name}</p>
      <div className="flex h-full">
        <Button variant="ghost" onClick={handleView}>
          <Eye className="h-5 w-5" />
        </Button>
        <Button variant="ghost" onClick={handleEdit}>
          <Edit className="h-5 w-5" />
        </Button>
        <Button variant="ghost" onClick={handleDelete}>
          <Trash2 className="h-5 w-5" />
        </Button>
      </div>
      <DeleteLearningPathDialog
        open={open}
        onDelete={onDelete}
        learningPath={learningPath}
        onOpenChange={hadnleOnOpenChange}
      />
    </Card>
  )
}
