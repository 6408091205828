import NavItem from "./nav-item"

export type Item = {
  id: number
  label: string
  link: string
  icon: React.ElementType
  disabled: boolean
}

export interface NavProps {
  items: Item[]
}

export default function Nav({ items }: NavProps) {
  return (
    <nav className="flex flex-1 flex-col gap-4 p-6">
      {items.map((item) => {
        return (
          <NavItem
            key={item.id}
            icon={item.icon}
            label={item.label}
            link={item.link}
            disabled={item.disabled}
          />
        )
      })}
    </nav>
  )
}
