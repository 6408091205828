import type { Level } from "@/services/api/challenges/get-user-challenges"
import AvailableLevelButton from "./available-level-button"
import BlockedLevelButton from "./blocked-level-button"
import CompletedLevelButton from "./completed-level-button"

interface ChallengeLevelsMapItemProps {
  level: Level
}

const ChallengeLevelsMapItem = ({ level }: ChallengeLevelsMapItemProps) => {
  const getButton = () => {
    if (level.status === "available") return <AvailableLevelButton levelId={level.id} />
    if (level.status === "blocked") return <BlockedLevelButton />
    if (level.status === "completed")
      return (
        <CompletedLevelButton
          score={level.score}
          levelId={level.id}
          lastCompletedSessionId={level.lastCompletedSessionId}
        />
      )
    return null
  }

  return (
    <div className="relative z-20 flex h-full w-full select-none items-center justify-center overflow-hidden rounded-full bg-neutral-100 shadow-custom-shadow">
      {getButton()}
    </div>
  )
}

export default ChallengeLevelsMapItem
