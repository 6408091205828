import LearningPathEdit from "@/modules/learning-paths/components/edit/edit-learning-path"
import { useParams } from "react-router-dom"

export default function LearningPathEditPage() {
  const { id = "" } = useParams<{ id: string }>()
  return (
    <section className="flex h-full w-full p-12">
      <LearningPathEdit id={id} />
    </section>
  )
}
