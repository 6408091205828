import type { StateCreator } from "zustand"
import { create } from "zustand"

interface State {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
}

const state: StateCreator<State> = (set, get) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  onToggle: () => set({ isOpen: !get().isOpen }),
})

export const usePremiumModal = create<State>(state)

export const { isOpen, onOpen, onClose, onToggle } = usePremiumModal.getState()
