import { client } from "@/config/client"

export interface GetSessionReviewParams {
  levelId: string
  sessionId: string
}

export type Review = {
  score: number
  title: string
  subtitle: string
  reviews: string[]
  nextChallengeLevelId: string | null
}

interface GetSessionReviewResponse {
  review: Review
}

const getSessionReview = ({ levelId, sessionId }: GetSessionReviewParams) => {
  return client
    .get<GetSessionReviewResponse>(`/v1/challenge-levels/${levelId}/session/${sessionId}/review`)
    .then((resp) => resp.data)
}

export default getSessionReview
