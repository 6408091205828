import { api } from "@/services/api"
import { useQuery } from "@tanstack/react-query"

interface UseGetUsersData {
  search: string
  offset: number
  limit: number
  edit: boolean
  plan: string
}

export default function useGetUsers(params: Partial<UseGetUsersData>) {
  const { edit, ...restOfParams } = params
  const query = useQuery({
    queryKey: ["get-users", params],
    queryFn: async () => api.user.getAll(restOfParams),
    select: (data) => data.data,
    refetchOnWindowFocus: false,
  })

  return query
}
