import { LockKeyhole } from "lucide-react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"

const BlockedLevelButton = () => {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger className="size-full cursor-default">
          <div className="flex h-full w-full cursor-default items-center justify-center rounded-full p-2">
            <div className="flex h-full w-full items-center justify-center rounded-full bg-[rgba(242,242,242,1)] shadow-custom-var-shadow">
              <LockKeyhole className="text-gray-600" />
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent className="shadow-[0px_24px_60px_0px_rgba(0,0,0,0.25) select-none rounded border-none bg-white p-4 text-center text-[13px] font-bold text-gray-600">
          <p>
            Alcanzá un <span className="text-fuchsia-600">70% de puntaje</span> en el nivel
          </p>
          <p>anterior para desbloquear este nivel</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default BlockedLevelButton
