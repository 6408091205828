import Dashboard from "@/pages/dashboard"
import { Navigate, type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "dashboard",
  element: <Dashboard />,
  children: [
    {
      path: "*",
      element: <Navigate to="dashboard" replace />,
    },
  ],
}

export default routes
