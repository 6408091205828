import axios from "axios"

export interface UploadFileToPresignedUrlParams {
  url: string
  file: File
}

export function uploadFileToPresignedUrl(data: UploadFileToPresignedUrlParams) {
  return axios.put(data.url, data.file, {
    headers: { "Content-Type": "application/pdf" },
  })
}
