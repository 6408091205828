import { client } from "@/config/client"
import type { SessionQuestion } from "../../learning-paths/get"
import type { ClientError } from "@educabot/fetcher"

export enum ProcessSessionErrors {
  U1 = "U1",
}

export interface ProcessSessionData {
  subThemeId: string
  documentId: string
  startPage: number
  endPage: number
}

export interface ProcessSessionResponse {
  questions: SessionQuestion[]
}

export function processSession(data: ProcessSessionData) {
  return client
    .post<string, ProcessSessionData>("/v1/documents/diagnoses", data)
    .catch((err: ClientError) => {
      if (err.code === ProcessSessionErrors.U1) {
        throw client.error(err, "No tienes más usos para diagnóstico")
      }
      throw err
    })
}
