import challenges from "./challenges"
import { user } from "./users"
import { admin } from "./admin"
import { documents } from "./documents"
import { learnignPaths } from "./learning-paths"
import { flashcards } from "./documents/flashcards"
import { stripeSessions } from "./stripe-sessions"
import { mindmaps } from "./mindmaps"
import diagnoses from "./diagnoses"

export const api = {
  challenges,
  documents,
  flashcards,
  learnignPaths,
  stripeSessions,
  user,
  admin,
  diagnoses,
  mindmaps,
}
