import createChallenge, {
  type CreateChallengeData,
} from "@/services/api/challenges/create-challenge"
import { useMutation } from "@tanstack/react-query"

const useCreateChallengeMutation = () => {
  const mutation = useMutation({
    mutationFn: (body: CreateChallengeData) => createChallenge(body),
  })
  return mutation
}

export default useCreateChallengeMutation
