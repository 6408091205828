import { X } from "lucide-react"
import { Link } from "react-router-dom"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import CreateChallengeByDocumentTab from "./create-challenge-by-document-tab"
import CreateChallengeByIATab from "./create-challenge-by-IA-tab"

const NewChallengePage = () => {
  return (
    <div className="fixed left-0 top-0 z-[9999] flex size-full flex-col gap-2 overflow-hidden bg-white">
      {/* Header. */}
      <div className="flex w-full shrink-0 items-center gap-4 p-4">
        <h6 className="w-full pl-10 text-center text-lg font-semibold">Nuevo desafío</h6>

        <Link
          to="/challenges"
          className="flex w-fit shrink-0 items-center justify-center gap-1 text-sm font-bold text-gray-500"
        >
          <X className="text-gray-500" />
        </Link>
      </div>

      {/* Content. */}
      <div className="mx-auto flex size-full max-w-[800px] flex-col overflow-hidden px-4">
        <Tabs
          defaultValue="document"
          className="flex size-full shrink-0 flex-col items-center gap-3 overflow-hidden"
        >
          <TabsList className="w-full overflow-hidden">
            <TabsTrigger value="document" className="px-5">
              Desde un archivo
            </TabsTrigger>
            <TabsTrigger value="ia" className="px-5">
              Con IA
            </TabsTrigger>
          </TabsList>

          <div className="size-full overflow-hidden">
            <CreateChallengeByDocumentTab />
            <CreateChallengeByIATab />
          </div>
        </Tabs>
      </div>
    </div>
  )
}

export default NewChallengePage
