import { type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "documents",
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: DocumentsPage } = await import("@/pages/documents")
        return { Component: DocumentsPage }
      },
    },
  ],
}

export default routes
