import { client } from "@/config/client"
import type { Activity, ActivityType } from "../activities/get"

type SaveMindmapPayload = {
  name: string
  type: ActivityType.MINDMAP
  content: string // markdown
}

interface SaveMindmapResponse {
  activity: Activity
}

export default function save(data: SaveMindmapPayload) {
  return client.post<SaveMindmapResponse, SaveMindmapPayload>("/v1/activities", data)
}
