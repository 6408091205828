import type { LearningSessionAnswer } from "@/services/api/diagnoses/create-feedback"
import type { SessionQuestion } from "@/services/api/learning-paths/get"
import type { StateCreator } from "zustand"
import { create } from "zustand"
import { persist } from "zustand/middleware"

interface State {
  learningSession: {
    questions: SessionQuestion[]
    answers: LearningSessionAnswer[]
    step: number
  }
}

interface Actions {
  setLearningSession: (learningSession: State["learningSession"]) => void
  clearState: () => void
}

const state: StateCreator<State & Actions> = (set) => ({
  learningSession: {
    questions: [],
    answers: [],
    step: 1,
  },
  setLearningSession: (learningSession) => set({ learningSession }),
  clearState: () => set({ learningSession: { questions: [], answers: [], step: 1 } }),
})

export const useLearningSession = create<State & Actions>()(
  persist(state, {
    name: "learning-sessions",
  }),
)
