import { client } from "@/config/client"
import type { LearningSession } from "@/services/api/users/info/get"

export enum QUESTION_TYPE {
  MULTIPLE_CHOICE = "multiple_choice",
  TRUE_FALSE = "true_false",
  ANALYSIS = "analysis",
  OPEN_ENDED = "open_ended",
}

export interface SessionQuestion {
  index: number
  answer: string
  question: string
  options: string[]
  type: QUESTION_TYPE
}

export interface Subtheme {
  id: string
  name: string
  score: number | null
  index: number | null
  components: unknown
  sessionQuestions: SessionQuestion[]
  sessions: LearningSession[]
  learningPathThemeId: string
  createdAt: string
  updatedAt: string
}

export function get(themeId: string) {
  return client.get<Subtheme[]>(`/v1/learning-paths/themes/${themeId}/subthemes`)
}
