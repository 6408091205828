import { useMutation } from "@tanstack/react-query"
import { useAuth } from "../stores"
import { client } from "@/config/client"

interface LoginWithEmailResponse {
  token: string
  user: {
    id: string
    name: string
    email: string
    avatar: string
    wasOnboarded: boolean
  }
}

export default function useLoginWithEmail() {
  const { logIn } = useAuth()

  const query = useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) => {
      return client.post<LoginWithEmailResponse, { email: string; password: string }>(
        import.meta.env.VITE_BASE_API_URL + "/v1/auths/email",
        {
          email,
          password,
        },
      )
    },
    onSuccess: (data) => {
      logIn(data.data.token, data.data.user)
    },
  })

  return query
}
