import type { LucideIcon } from "lucide-react"
export interface Metric {
  title: string
  value: string
  icon: LucideIcon
}

export interface MetricCardProps {
  title: string
  icon: LucideIcon
  children: JSX.Element | JSX.Element[]
  isLoading?: boolean
}

export default function MetricCard({ title, children, icon: Icon }: MetricCardProps) {
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between gap-3 lg:justify-start">
        <p className="text-sm font-medium text-orange-500 sm:text-xl">{title}</p>
        <span className="flex size-8 items-center justify-center rounded-lg bg-orange-50 p-2 sm:size-12 sm:p-3">
          <Icon className="size-6 self-center text-orange-500 sm:size-12" />
        </span>
      </div>
      {children}
    </div>
  )
}
