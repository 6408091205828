import { Button } from "@/components/ui/button"
import { LoaderCircle } from "lucide-react"

interface FloatingGameButtonProps {
  onClickBtn: () => void
  isLoading: boolean
  isDisabled: boolean
}

const FloatingGameButton = ({ onClickBtn, isLoading, isDisabled }: FloatingGameButtonProps) => {
  return (
    <div className="fixed bottom-0 left-0 z-10 mx-auto w-full max-w-[800px] bg-white p-4 md:static md:p-3">
      <Button
        className="w-full rounded-[8px] bg-orange-500 p-3 text-[14px] font-bold text-white transition duration-200 hover:bg-orange-600 disabled:bg-neutral-200 disabled:text-neutral-500"
        onClick={onClickBtn}
        disabled={isLoading || isDisabled}
      >
        {isLoading && <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />}
        {isLoading ? "Verificando..." : "Verificar"}
      </Button>
    </div>
  )
}

export default FloatingGameButton
