import ChallengesAPI from "@/services/api/challenges"
import { useMutation, useQueryClient } from "@tanstack/react-query"

const useDeleteChallengeMutation = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ChallengesAPI.deleteChallenge,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userChallenges"] })
    },
  })
  return mutation
}

export default useDeleteChallengeMutation
