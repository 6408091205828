import { api } from "@/services/api"
import { useQuery } from "@tanstack/react-query"

export default function useGetLpScore(id: string) {
  const query = useQuery({
    queryKey: ["get-learnign-path-score", id],
    queryFn: async () => api.learnignPaths.getScore(id),
    select: (data) => data.data,
  })

  return query
}
