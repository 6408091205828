import { client } from "@/config/client"

export interface UpdateUser {
  plan: string
  lives: number
  country: string
}

export function update(id: number, data: UpdateUser) {
  return client.put(`/v1/users/${id}`, data)
}
