import { TabsContent } from "@/components/ui/tabs"
import { useUser } from "@/modules/user/stores"
import { Plus, File } from "lucide-react"
import { useState } from "react"
import { useDropzone } from "react-dropzone"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import { useUploadDocument } from "@/modules/documents/queries/use-upload-document"
import { useCreateDocument } from "@/modules/documents/queries/use-create"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Button } from "@/components/ui/button"
import axios from "axios"
import { useNavigate, useSearchParams } from "react-router-dom"
import type { Document } from "@/services/api/documents/get"
import { ScrollArea } from "@/components/ui/scroll-area"

const CreateChallengeByDocumentTab = () => {
  const navigate = useNavigate()
  const { user } = useUser()
  const { selectedLearningPath } = useLearningPaths()
  const { documents: userDocuments, id: userId } = user
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null)
  const [documents, setDocuments] = useState<Document[]>(userDocuments)

  const { mutateAsync: uploadFile, isPending: isUploadPending } = useUploadDocument()
  const { mutateAsync: createDocument, isPending: isDocumentPending } = useCreateDocument()
  const isLoading = isUploadPending || isDocumentPending
  const [_, setSearchParams] = useSearchParams()

  const handleFileUpload = async (file: File) => {
    if (!file) return
    const response = await uploadFile()
    const { url, objectId } = response.data
    await axios.put(url, file)

    const newDocument = await createDocument({
      file: file.name,
      learningPath: selectedLearningPath.id,
      userId,
      objectId: objectId,
    })
    setDocuments([...documents, newDocument.data])
  }

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    handleFileUpload(file)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    },
    multiple: false,
    maxFiles: 1,
    onDrop,
  })

  const onValueChange = (newFileId: string) => {
    const newSelectedDocument = userDocuments.find((document) => document.id === newFileId)
    if (!newSelectedDocument) return
    setSelectedDocument(newSelectedDocument)
  }

  const handleOnContinue = () => {
    if (!selectedDocument) return
    navigate("document")
    setSearchParams({ id: selectedDocument.id })
  }

  return (
    <TabsContent value="document" className="m-0 size-full overflow-hidden">
      <section className="flex size-full flex-col gap-5 overflow-hidden">
        <div
          {...getRootProps({
            className:
              "dropzone flex w-full items-center justify-center gap-1 rounded-[8px] border border-solid border-orange-100 bg-orange-100 hover:border-orange-300 cursor-pointer py-1 shrink-0",
          })}
        >
          <input {...getInputProps()} />
          <Plus className="text-orange-500" />
          <span className="text-sm font-bold text-orange-500">Cargar nuevo documento</span>
        </div>
        {isLoading ? (
          <p className="flex size-full items-center justify-center">Cargando...</p>
        ) : (
          <section className="flex size-full flex-col overflow-hidden">
            <ScrollArea className="size-full overflow-y-auto overflow-x-hidden">
              {documents.length > 0 ? (
                <div className="flex w-full flex-col gap-4">
                  <RadioGroup
                    value={selectedDocument?.id}
                    onValueChange={onValueChange}
                    className="flex w-full flex-col gap-4"
                  >
                    {documents.map((document, i) => {
                      const isSelectedOption = selectedDocument?.id === document.id

                      return (
                        <div
                          className={`w-full cursor-pointer overflow-hidden rounded-[8px] ${isSelectedOption ? "bg-neutral-200 hover:bg-neutral-300" : "bg-transparent hover:bg-neutral-50"}`}
                          key={i}
                        >
                          <RadioGroupItem value={document.id} id={document.id} className="hidden" />
                          <Label
                            htmlFor={document.id}
                            className={
                              "flex w-full cursor-pointer items-stretch gap-2 overflow-hidden"
                            }
                          >
                            <div className="flex size-12 shrink-0 items-center justify-center">
                              <File size={18} />
                            </div>

                            <div
                              className={`flex w-full items-center overflow-hidden border-b ${isSelectedOption ? "border-none" : "border-gray-300"}`}
                            >
                              <p
                                className={`w-full overflow-hidden truncate text-base font-normal`}
                                title={document.filename ?? ""}
                              >
                                {document.filename}
                              </p>
                            </div>
                          </Label>
                        </div>
                      )
                    })}
                  </RadioGroup>
                </div>
              ) : (
                <p className="mt-5 select-none text-center text-sm text-muted-foreground">
                  Aún no tienes documentos cargados.
                </p>
              )}
            </ScrollArea>
            <div className="w-full shrink-0 bg-white py-6">
              <Button
                type="button"
                onClick={handleOnContinue}
                className="w-full rounded-[8px] hover:bg-orange-600"
                disabled={!selectedDocument}
              >
                Continuar
              </Button>
            </div>
          </section>
        )}
      </section>
    </TabsContent>
  )
}

export default CreateChallengeByDocumentTab
