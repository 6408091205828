import { api } from "@/services/api"
import { useMutation } from "@tanstack/react-query"

export const useDownloadSignedDocument = () => {
  const mutation = useMutation({
    mutationKey: ["download-signed-document"],
    mutationFn: api.documents.downloadSigned,
  })

  return mutation
}
