import { client } from "@/config/client"

interface CreatePresignedUrlResponse {
  url: string
  objectId: string
}

export function createPresignedUrl() {
  return client.post<CreatePresignedUrlResponse>(`/v1/documents/signed`)
}
