import { client } from "@/config/client"
import type { Document } from "./get"

type UpdateDocumentResponse = Document

interface UpdateDocumentData {
  id: string
  data: Partial<Document>
}

export function update(data: UpdateDocumentData) {
  return client.put<UpdateDocumentResponse, UpdateDocumentData>(`/v1/documents/${data.id}`, data)
}
