import LearningPaths from "@/modules/learning-paths/components/learning-paths"
import CreateLearningPath from "@/modules/learning-paths/components/create/create-learning-path"
import useGetLps from "@/modules/app/queries/use-get-lps"

export default function LearningPathsPage() {
  const { data: learningPaths, isSuccess, refetch } = useGetLps()

  const handleDelete = () => {
    refetch()
  }

  const handleOnCreate = () => {
    refetch()
  }

  return (
    <div className="flex w-full flex-col gap-4 p-12">
      <div className="justify-betwee flex items-center">
        <div className="flex h-full w-full items-center">
          <h2 className="self-center text-3xl font-medium text-primary">Mis materias</h2>
        </div>
        <CreateLearningPath onCreate={handleOnCreate} />
      </div>
      <p className="font-medium">Gestiona y crea tus materias</p>
      {isSuccess && <LearningPaths onDelete={handleDelete} learningPaths={learningPaths ?? []} />}
    </div>
  )
}
