import Clarity from "@microsoft/clarity"

interface ClarityIdentifyInitOptions {
  id: string
  sessionId?: string
  pageId?: string
  friendlyName?: string
}

/**
 * Custom hook to integrate Microsoft Clarity identify functionality.
 *
 * @param options - The options for Clarity identify.
 * @param options.id - The unique identifier for the user.
 * @param options.sessionId - The session identifier.
 * @param options.pageId - The page identifier.
 * @param options.friendlyName - A friendly name for the user.
 */
export default function useClarityIdentify() {
  const init = ({ id, sessionId, pageId, friendlyName }: ClarityIdentifyInitOptions) => {
    Clarity.init(import.meta.env.VITE_CLARITY_PROJECT_ID)
    Clarity.identify(id, sessionId, pageId, friendlyName)
  }

  const setTag = (key: string, value: string | string[]) => {
    Clarity.setTag(key, value)
  }

  return {
    init,
    setTag,
  }
}
