import { STUDENT_ROLE, type UserInfo } from "@/services/api/users/info/get"
import { type StateCreator, create } from "zustand"
import { persist } from "zustand/middleware"

interface UserState {
  user: UserInfo
  setUser: (user: UserInfo) => void
}

const state: StateCreator<UserState> = (set) => ({
  user: {
    id: "",
    name: "",
    email: "",
    country: "",
    role: "STUDENT",
    plan: "FREE",
    studentRole: STUDENT_ROLE.SECONDARY,
    avatar: "",
    lives: 0,
    activities: [],
    learningSessions: [],
    documents: [],
    userPoints: [],
    refillLivesAt: "",
    diagnoseUses: 0,
  },
  setUser: (user: UserInfo) => set({ user }),
})

export const useUser = create<UserState>()(
  persist(state, {
    name: "user_info",
  }),
)

export const { user } = useUser.getState()
