import React from "react"

const GoogleMeetIcon: React.FC = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1235_1689)">
      <path
        d="M11.5557 8L13.4512 10.1667L16.0001 11.7956L16.4446 8.01334L16.0001 4.31556L13.4023 5.74667L11.5557 8Z"
        fill="#00832D"
      />
      <path
        d="M0.555664 11.4444V14.6667C0.555664 15.4033 1.15233 16 1.889 16H5.11122L5.77789 13.5644L5.11122 11.4444L2.90011 10.7778L0.555664 11.4444Z"
        fill="#0066DA"
      />
      <path
        d="M5.11122 0L0.555664 4.55556L2.90011 5.22222L5.11122 4.55556L5.76678 2.46444L5.11122 0Z"
        fill="#E94235"
      />
      <path d="M5.11122 4.55555H0.555664V11.4444H5.11122V4.55555Z" fill="#2684FC" />
      <path
        d="M18.9113 1.92889L16.0002 4.31556V11.7956L18.9247 14.1933C19.3624 14.5356 20.0024 14.2233 20.0024 13.6667V2.44445C20.0024 1.88111 19.348 1.57223 18.9113 1.92889ZM11.5558 8V11.4444H5.11133V16H14.6669C15.4036 16 16.0002 15.4033 16.0002 14.6667V11.7956L11.5558 8Z"
        fill="#00AC47"
      />
      <path
        d="M14.6669 0H5.11133V4.55556H11.5558V8L16.0002 4.31778V1.33333C16.0002 0.596667 15.4036 0 14.6669 0Z"
        fill="#FFBA00"
      />
    </g>
    <defs>
      <clipPath id="clip0_1235_1689">
        <rect width="19.4444" height="16" fill="white" transform="translate(0.555664)" />
      </clipPath>
    </defs>
  </svg>
)

export default GoogleMeetIcon
