import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog"
import { Button } from "@/components/button"
import { toast } from "sonner"
import { useEditChallengeModal } from "../../stores/use-edit-challenge-modal-store"
import useEditChallengeMutation from "../../queries/use-edit-challenge-mutation"
import { useEffect } from "react"
import { Input } from "@/components/ui/input"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

const schema = z.object({
  name: z.string().trim().min(1, "El nombre es obligatorio.").max(50, "Máximo 50 caracteres."),
})

type FormData = z.infer<typeof schema>

const DeleteChallengeModal = () => {
  const { isOpen, challengeToEdit, closeModal } = useEditChallengeModal()
  const { isPending, mutateAsync } = useEditChallengeMutation()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { name: "" },
  })

  useEffect(() => {
    if (challengeToEdit) {
      reset({ name: challengeToEdit.name })
    }
  }, [challengeToEdit, reset])

  const handleOnClose = () => {
    if (isPending) return
    closeModal()
  }

  const handleOnEdit = async (data: FormData) => {
    if (!challengeToEdit) return

    try {
      const body = { name: data.name.trim() }
      await mutateAsync({ challengeId: challengeToEdit.id, body })
      toast.success("El Desafío ha sido editado correctamente.")
    } catch {
      toast.error("Ha ocurrido un error al editar el desafío.")
    } finally {
      closeModal()
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOnClose}>
      <DialogContent className="w-fit min-w-[320px]">
        <DialogHeader className="flex items-start">
          <DialogTitle>Renombrar desafío</DialogTitle>
          <DialogDescription className="w-full">
            <form className="mt-1 w-full" onSubmit={handleSubmit(handleOnEdit)}>
              <Input
                placeholder="Nombre del desafío"
                {...register("name")}
                className="rounded-[8px] text-black"
              />
              {errors.name && (
                <span className="mt-2 block text-xs text-red-600">{errors.name.message}</span>
              )}
            </form>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex flex-row items-center justify-end gap-1">
          <DialogClose asChild>
            <Button type="button" className="bg-gray-300 text-black" disabled={isPending}>
              Cancelar
            </Button>
          </DialogClose>

          <Button
            type="submit"
            onClick={handleSubmit(handleOnEdit)}
            loading={isPending}
            disabled={isPending}
          >
            Renombrar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteChallengeModal
