import { get } from "./get"
import { create } from "./create"
import { deleteById } from "./deleteById"
import { getById } from "./getById"
import themes from "./themes"
import { update } from "./update"
import { getScore } from "./getScore"
import { deleteTheme } from "./themes/delete"
import { deleteSubtheme } from "./themes/subthemes/delete"

export const learnignPaths = {
  get,
  create,
  getById,
  deleteById,
  themes,
  update,
  getScore,
  deleteTheme,
  deleteSubtheme,
}
