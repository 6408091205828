import { useUser } from "../stores"

const useCanContinueLearning = () => {
  const { user } = useUser()
  const { lives, plan } = user
  const canContinueLearning = plan === "PRO" || lives > 0
  return canContinueLearning
}

export default useCanContinueLearning
