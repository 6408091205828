import type { Plan } from "../types/plan"
import React from "react"
import { cn } from "@/utils/cn"

interface PlanCardProps {
  plan: Plan
  selected: boolean
  onSelect: () => void
  userCountry: string
  usdToArs: number
}

export const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  selected,
  onSelect,
  userCountry,
  usdToArs,
}) => {
  const price =
    userCountry === "argentina"
      ? `$ ${Math.round(plan.priceInUsd * usdToArs)}`
      : `US$ ${plan.priceInUsd}`

  return (
    <div
      onClick={onSelect}
      className={cn(
        "group relative flex cursor-pointer flex-col rounded-xl p-4",
        "border",
        selected
          ? "border-orange-500 bg-gradient-to-br from-orange-50 to-white ring-2 ring-orange-500"
          : "border-gray-200 hover:border-orange-200",
      )}
    >
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-bold text-gray-900 group-hover:text-orange-600">
            {plan.name}
          </h2>
          <p className="text-lg font-bold text-gray-900">{price}</p>
        </div>
        <p className="text-sm text-gray-600">{plan.description}</p>
        {plan.students > 1 && (
          <div className="flex items-center gap-2">
            <span className="rounded-full bg-orange-100 px-3 py-1 text-xs font-medium text-orange-600">
              {plan.students} estudiantes
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
