import { api } from "@/services/api"
import type { UploadFileToPresignedUrlParams } from "@/services/api/documents/upload-file-to-presigned-url"
import { useMutation } from "@tanstack/react-query"

export const usePresignedUrl = () => {
  const create = useMutation({
    mutationKey: ["create-presigned-url"],
    mutationFn: async () => api.documents.createPresignedUrl(),
  })

  const uploadFile = useMutation({
    mutationKey: ["upload-file-to-presigned-url"],
    mutationFn: async (data: UploadFileToPresignedUrlParams) =>
      api.documents.uploadFileToPresignedUrl(data),
  })

  return { create, uploadFile }
}
