import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "sonner"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Dialog, DialogHeader, DialogContent, DialogTitle } from "@/components/ui/dialog"
import { Button } from "@/components/button"
import useCreateLp from "@/modules/app/queries/use-create-lp"

const schema = z.object({
  name: z
    .string()
    .min(3, { message: "El nombre debe tener al menos 3 caracteres" })
    .max(100, { message: "El nombre no puede exceder 100 caracteres" })
    .regex(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s]+$/, {
      message: "El nombre solo puede contener letras, números y espacios",
    }),
})

type FormValues = z.infer<typeof schema>

interface CreateLearningPathDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  onCreate: (name: string) => void
}

export default function CreateLearningPathDialog({
  open,
  setOpen,
  onCreate,
}: CreateLearningPathDialogProps) {
  const { mutateAsync, isPending } = useCreateLp()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
    },
  })

  const onSubmit = async (data: FormValues) => {
    try {
      await mutateAsync(data.name)
      onCreate(data.name)
      toast.success("Materia creada exitosamente")
      handleClose()
    } catch {
      toast.error("Ocurrió un error al crear la materia. Por favor intenta de nuevo.")
    }
  }

  const handleClose = () => {
    reset()
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Crear materia</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Nombre de la materia</Label>
            <Input id="name" placeholder="Ej: Macroeconomía básica" {...register("name")} />
            {errors.name && <p className="text-sm text-red-500">{errors.name.message}</p>}
          </div>
          <div className="flex justify-end gap-3">
            <Button type="button" variant="outline" onClick={handleClose} disabled={isPending}>
              Cancelar
            </Button>
            <Button type="submit" disabled={isPending} loading={isPending}>
              Crear
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
