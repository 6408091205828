import { client } from "@/config/client"

export enum QUESTION_TYPE {
  MULTIPLE_CHOICE = "multiple-choice",
}

export interface QuestionOption {
  id: string
  index: number
  label: string
  answer: string
}

export enum QUESTION_DIFFICULTY {
  EASY = "easy",
  MEDIUM = "medium",
  HARD = "hard",
}

export interface Question {
  id: string
  question: string
  type: QUESTION_TYPE
  difficulty: QUESTION_DIFFICULTY
  currentQuestionNumber: number
  options: string[]
}

export interface FirstQuestionToAnswer {
  id: string
  numberOfQuestions: number
  question: Question
}

const createLevelSession = (_levelId: string) => {
  return client
    .post<FirstQuestionToAnswer>(`/v1/challenge-levels/${_levelId}/session`)
    .then((resp) => resp.data)
}

export default createLevelSession
