import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { toast } from "sonner"
import useDeleteLpById from "@/modules/app/queries/use-delete-lp-by-id"
import type { LearningPath } from "@/services/api/learning-paths/get"

interface DeleteLearningPathDialogProps {
  open: boolean
  learningPath: LearningPath
  onDelete: () => void
  onOpenChange: (open: boolean) => void
}

export default function DeleteLearningPathDialog({
  open,
  onOpenChange,
  onDelete,
  learningPath,
}: DeleteLearningPathDialogProps) {
  const { mutateAsync } = useDeleteLpById(learningPath.id)

  const handleOnClickDelete = async () => {
    const result = await mutateAsync()
    if (result) {
      onDelete()
      onOpenChange(false)
      toast.success("Materia eliminada")
      return
    }

    toast.error("No se pudo eliminar la materia")
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Eliminar materia</AlertDialogTitle>
          <AlertDialogDescription>
            ¿Estás seguro que deseas eliminar{" "}
            <span className="font-bold text-destructive">{learningPath.name}</span>?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => onOpenChange(false)}>Cancelar</AlertDialogCancel>
          <AlertDialogAction onClick={handleOnClickDelete}>Eliminar</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
