import SidePanel from "@/modules/app/components/side-panel"
import Header from "./header"
import Body from "./body"
import { useEffect } from "react"
import { MobileNavbar } from "@/modules/app/components/mobile-navbar"
import { useUser } from "@/modules/user/stores"
import useBugSnag from "@/app/hooks/useBugSnag"
import useClarityIdentify from "@/app/hooks/useClarityIdentify"

export default function Main() {
  const { user } = useUser()
  const bug = useBugSnag()
  const clarity = useClarityIdentify()

  useEffect(() => {
    bug.init({
      id: user.id,
      email: user.email,
      name: user.name,
    })

    clarity.init({
      id: user.id,
      friendlyName: user.name,
    })

    clarity.setTag("user_email", user.email)
  }, [user.id])

  return (
    <div className="grid h-full w-full md:grid-cols-[94px,1fr]">
      <div className="hidden h-full w-full md:flex">
        <SidePanel />
      </div>
      <div className="absolute md:hidden">
        <MobileNavbar />
      </div>
      <div className="grid grid-rows-[74px_1fr] overflow-auto bg-gray-50 max-md:mb-14">
        <Header />
        <Body />
      </div>
    </div>
  )
}
