import createLevelSession from "@/services/api/challenges/create-level-session"
import { useMutation, useQueryClient } from "@tanstack/react-query"

const useCreateLevelSessionMutation = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (levelId: string) => createLevelSession(levelId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-user"] })
    },
  })
  return mutation
}

export default useCreateLevelSessionMutation
