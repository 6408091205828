import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { Book, HeartHandshake, TestTube, Wrench } from "lucide-react"

interface PlanPresentationStepProps {
  next: () => void
}

export default function PlanPresentationStep({ next }: PlanPresentationStepProps) {
  const features = [
    {
      icon: Book,
      name: "Documentos ilimitados",
      description: "Sube todos los documentos que necesites",
    },
    {
      icon: TestTube,
      name: "Autoevaluaciones sin límites",
      description: "Practica tanto como quieras",
    },
    {
      icon: Wrench,
      name: "Todas las herramientas",
      description: "Acceso completo a funcionalidades",
    },
    {
      icon: HeartHandshake,
      name: "Soporte privilegiado",
      description: "Atención prioritaria y personalizada",
    },
  ]

  return (
    <div className="px-4 py-5">
      <div className="space-y-3 text-center">
        <h1 className="text-2xl font-bold text-primary sm:text-3xl">Obtené Tich premium</h1>
        <p className="text-sm text-gray-600">
          Los usuarios premium tienen{" "}
          <span className="font-semibold">5 veces más probabilidades</span> de aprobar sus exámenes.
        </p>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
        {features.map((feature, index) => (
          <Card key={index} className="flex items-start gap-4 p-3 sm:p-4">
            <div className="rounded-lg bg-orange-100 p-2">
              <feature.icon className="h-5 w-5 text-orange-500" />
            </div>

            <div>
              <h3 className="text-sm font-semibold text-gray-900 sm:text-base">{feature.name}</h3>
              <p className="text-xs text-gray-500 sm:text-sm">{feature.description}</p>
            </div>
          </Card>
        ))}
      </div>

      <div className="mt-6 space-y-4">
        <Button className="w-full py-2.5 text-sm font-medium" onClick={next}>
          Comenzá ahora
        </Button>

        <div className="flex justify-center">
          <div className="inline-flex items-center gap-1.5 rounded-full bg-gray-50 px-3 py-1.5 text-xs text-gray-600">
            <span className="hidden sm:inline">¿Tenés alguna pregunta?</span>
            <a
              href="https://wa.link/74w2qk"
              target="_blank"
              className="font-medium text-green-600 transition-colors hover:text-green-700"
            >
              WhatsApp
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
