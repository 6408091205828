import { useNavigate, useParams } from "react-router-dom"
import useCreateLevelSessionMutation from "@/modules/challenges/queries/use-create-level-session-mutation"
import { useEffect } from "react"
import GameLayout from "@/modules/challenges/features/game/game-layout"
import { Button } from "@/components/button"

const Game = () => {
  const { levelId } = useParams()
  const { isPending, isError, mutate, data } = useCreateLevelSessionMutation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!levelId) return
    mutate(levelId)
  }, [levelId])

  return (
    <div className="fixed left-0 top-0 z-[50] h-full w-full overflow-hidden bg-white">
      {isPending ? (
        <p className="m-auto flex h-full w-full items-center justify-center">Cargando...</p>
      ) : isError ? (
        <p className="m-auto flex h-full w-full flex-col items-center justify-center gap-3">
          <span>Algo salió mal.</span>
          <Button type="button" onClick={() => navigate("/challenges")}>
            Volver
          </Button>
        </p>
      ) : data && levelId ? (
        <GameLayout levelId={levelId} session={data} />
      ) : null}
    </div>
  )
}

export default Game
