import { useState } from "react"
import useGetLpById from "@/modules/app/queries/use-get-lp-by-id"
import { Progress } from "@/components/ui/progress"
import { ChevronDown, ChevronUp } from "lucide-react"
import SubTheme from "./learning-path-subtheme"
import useGetLpScore from "@/modules/app/queries/use-get-lp-score"

interface LearningPathProps {
  id: string
}

export default function LearningPath({ id }: LearningPathProps) {
  const { data: learningPath, isFetching } = useGetLpById(id)
  const [expandedThemes, setExpandedThemes] = useState<string[]>([])
  const { data: scoreResponse } = useGetLpScore(id)

  const handleToggleTheme = async (themeId: string) => {
    setExpandedThemes((themes) => {
      if (themes.includes(themeId)) {
        return themes.filter((t) => t != themeId)
      }
      return themes.concat(themeId)
    })
  }

  const themes = learningPath?.themes || []

  if (isFetching) {
    return (
      <div className="flex w-full flex-col gap-4 rounded-lg bg-white p-8 shadow-md">Loading...</div>
    )
  }

  return (
    <div className="flex w-full flex-col gap-4 rounded-lg bg-white p-8 shadow-md">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-2xl font-bold text-orange-500">{learningPath?.name}</h2>
        <div className="flex items-center gap-2">
          <p className="text-sm font-medium text-gray-600">
            {scoreResponse?.score.toFixed() ?? 0}%
          </p>
          <Progress className="w-32" value={scoreResponse?.score ?? 0} />
        </div>
      </div>
      <div className="flex flex-col space-y-4">
        {themes
          ?.sort((a, b) => a.index! - b.index!)
          .map(({ id, name, subthemes, score }) => {
            return (
              <div key={id} className="pb-4">
                <div
                  className="mb-2 flex cursor-pointer items-center justify-between"
                  onClick={() => handleToggleTheme(id)}
                >
                  <h3 className="text-lg font-medium">{name}</h3>
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-medium text-gray-600">{score?.toFixed() ?? 0}%</p>
                    <Progress className="w-32" value={score ?? 0} />
                    {expandedThemes.includes(id) ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </div>
                </div>
                {expandedThemes.includes(id) && (
                  <div className="mt-4 flex flex-col space-y-4">
                    {subthemes?.map((subtheme, index) => (
                      <SubTheme key={index} subTheme={subtheme} />
                    ))}
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}
