import { api } from "@/services/api"
import { useMutation } from "@tanstack/react-query"

export const useUploadDocument = () => {
  const mutation = useMutation({
    mutationKey: ["upload-document"],
    mutationFn: () => api.documents.createPresignedUrl(),
  })

  return mutation
}
