import { Navigate, Outlet, type RouteObject } from "react-router-dom"
import AdminPage from "@/pages/admin"
import HasRole from "@/modules/auth/features/has-role-route"

const routes: RouteObject = {
  path: "admin",
  element: (
    <HasRole roles={["ADMIN"]} redirect="/dashboard">
      <Outlet />
    </HasRole>
  ),
  children: [
    {
      index: true,
      element: <AdminPage />,
    },
    {
      path: "*",
      element: <Navigate to="admin" replace />,
    },
  ],
}

export default routes
