import { Badge } from "@/components/ui/badge"
import { cn } from "@/lib/utils"
import React from "react"

interface FileItemProps {
  fileName: string
  isSelected: boolean
  onClick?: () => void
}

export const FileItem: React.FC<FileItemProps> = ({ fileName, isSelected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        "flex cursor-pointer items-center justify-between rounded-lg border p-3 hover:bg-primary-100",
        isSelected && "bg-primary font-medium text-white hover:bg-primary",
      )}
    >
      <span className="text-sm">{fileName}</span>
      {isSelected && (
        <Badge className="bg-primary-50 text-xs font-medium text-primary-600">Seleccionado</Badge>
      )}
    </div>
  )
}
