import { GetPremiumModal } from "@/modules/app/components/get-premium-modal"
import { usePremiumModal } from "@/modules/app/stores/use-premium-modal"
import { Outlet } from "react-router-dom"
import NoLifeAlert from "@/modules/app/components/no-life-alert"

export default function Body(): React.ReactElement {
  const { isOpen, onToggle } = usePremiumModal()
  return (
    <div className="flex h-full w-full">
      <Outlet />
      <GetPremiumModal open={isOpen} onToggle={onToggle} />
      <NoLifeAlert />
    </div>
  )
}
