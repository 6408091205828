import { type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "onboarding",
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: OnboardingPage } = await import("@/pages/onboarding")
        return { Component: OnboardingPage }
      },
    },
  ],
}

export default routes
