import { api } from "@/services/api"
import { useMutation } from "@tanstack/react-query"

export const useCreateStripeSession = () => {
  const mutation = useMutation({
    mutationKey: ["use-create-stripe-session"],
    mutationFn: (productId: string) => api.stripeSessions.create({ productId }),
  })
  return mutation
}
