import { Progress } from "@/components/ui/progress"
import { X as XIcon } from "lucide-react"
import { useNavigate } from "react-router-dom"

interface GameHeaderProps {
  currentQuestionNumber: number
  numberOfQuestions: number
  isDisabled: boolean
}

const GameHeader = ({ currentQuestionNumber, numberOfQuestions, isDisabled }: GameHeaderProps) => {
  const navigate = useNavigate()

  const handleOnClose = () => {
    navigate("/challenges", { replace: true })
  }

  return (
    <div className="flex w-full items-center justify-between gap-4 p-5">
      <div className="mx-auto w-full md:max-w-[840px] md:pl-12">
        <Progress
          value={(currentQuestionNumber / numberOfQuestions) * 100}
          className="h-3 bg-neutral-200 text-orange-500"
        />
      </div>

      <button
        type="button"
        className="shrink-0 text-gray-500"
        onClick={handleOnClose}
        disabled={isDisabled}
      >
        <XIcon />
      </button>
    </div>
  )
}

export default GameHeader
