import { type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "tito",
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: TitoPage } = await import("@/pages/tito")
        return { Component: TitoPage }
      },
    },
  ],
}

export default routes
