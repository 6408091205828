import { client } from "@/config/client"
import type { Theme } from "./get"

export interface LearningPath {
  id: string
  name: string
  description: string | null
  score: number | null
  additionalInfo: string | null
  bibliography: string | null
  userId: string
  createdAt: string
  updatedAt: string
  themes?: Theme[]
}

export type GetLpByIdResponse = LearningPath

export function getById(id: string) {
  return client.get<GetLpByIdResponse>("/v1/learning-paths/" + id)
}
