import { QUESTION_DIFFICULTY } from "@/services/api/challenges/create-level-session"

const DifficultyLabel = ({ difficulty }: { difficulty: QUESTION_DIFFICULTY }) => {
  switch (difficulty) {
    case QUESTION_DIFFICULTY.EASY: {
      return (
        <>
          <span>Fácil</span>
          <span>🍃</span>
        </>
      )
    }
    case QUESTION_DIFFICULTY.MEDIUM: {
      return (
        <>
          <span>Medio</span>
          <span>💪</span>
        </>
      )
    }
    case QUESTION_DIFFICULTY.HARD: {
      return (
        <>
          <span>Difícil</span>
          <span>🌶️</span>
        </>
      )
    }
    default:
      return null
  }
}

export default DifficultyLabel
