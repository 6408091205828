import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer"
import { Button } from "@/components/ui/button"
import { Crown } from "lucide-react"

interface PendingChallengesAlertProps {
  isOpen: boolean
  setIsOpen: () => void
}

const PendingChallengesAlert = ({ isOpen, setIsOpen }: PendingChallengesAlertProps) => {
  const handleOnClick = () => {
    // navigate("/plans")
  }

  return (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DrawerContent
        className={`mx-auto w-full max-w-[500px] overflow-hidden border-none bg-white p-3 pt-8 shadow-[0px_24px_60px_0px_rgba(0,0,0,0.25)] [&>*:first-child]:hidden`}
      >
        <DrawerHeader className="p-0">
          <DrawerTitle className="mx-auto max-w-[290px] p-0 text-center text-[16px] font-bold text-gray-600">
            Terminá el desafío anterior o <span className="text-fuchsia-600">hacete Premium</span> y
            agregá documentos y desafíos sin limites.
          </DrawerTitle>
        </DrawerHeader>
        <DrawerFooter className="mt-6 w-full p-0">
          <Button
            type="button"
            onClick={handleOnClick}
            className="flex w-full items-center justify-between rounded-[8px] bg-fuchsia-700 text-sm text-white transition-colors duration-150 hover:bg-fuchsia-800"
          >
            <span>Desafíos ilimitados</span>
            <span className="flex items-center justify-center gap-2">
              Probar Premium <Crown />
            </span>
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default PendingChallengesAlert
