import { api } from "@/services/api"
import type { CreateReviewData } from "@/services/api/diagnoses/create-review"
import { useMutation } from "@tanstack/react-query"

export const useCreateReview = () => {
  const mutation = useMutation({
    mutationKey: ["create-review"],
    mutationFn: async (data: CreateReviewData) => api.diagnoses.createReview(data),
  })
  return mutation
}
