import React, { useEffect } from "react"
import { CreateSession } from "@/modules/learning-paths/components/subthemes/create-session"
import { useLearningSession } from "@/modules/learning-paths/stores/use-learning-session"
import QuestionType from "@/modules/learning-paths/components/subthemes/question-types"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"

export default function SubthemeSessionPage(): React.ReactNode {
  const { selectedSubtheme } = useLearningPaths()
  const { learningSession, clearState } = useLearningSession()
  const { step, questions = [] } = learningSession
  const currentQuestion = questions[step - 1]

  useEffect(() => {
    return () => {
      clearState()
    }
  }, [clearState])

  return (
    <section className="flex h-max w-full flex-col gap-10 p-6">
      <h1 className="text-2xl font-bold">{selectedSubtheme.name}</h1>
      {!currentQuestion ? <CreateSession /> : <QuestionType currentQuestion={currentQuestion} />}
    </section>
  )
}
