import { createFeedback } from "./create-feedback"
import { createReview } from "./create-review"
import { createSession } from "./create-session"

const diagnoses = {
  createReview,
  createSession,
  createFeedback,
}

export default diagnoses
