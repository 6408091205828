import { client } from "@/config/client"

export type LevelBase = {
  id: string
  order: number
}

export type ConfigByStatus =
  | { status: "blocked" }
  | { status: "available" }
  | {
      status: "completed"
      score: number
      lastCompletedSessionId: string
    }

export type Level = LevelBase & ConfigByStatus

export interface Challenge {
  id: string
  name: string
  levels: Level[]
}

export interface getUserChallengesResponse {
  more: boolean
  challenges: Challenge[]
}

export interface GetUserChallengesParams {
  limit: number
  offset: number
  learningPathId: string
}

const getUserChallenges = (params: GetUserChallengesParams): Promise<getUserChallengesResponse> => {
  return client
    .get<getUserChallengesResponse>("/v1/challenges", { params })
    .then((resp) => resp.data)
}

export default getUserChallenges
