import { useDebounceValue } from "usehooks-ts"
import useGetUser from "@/modules/user/queries/use-get-user"
import useGetLps from "../queries/use-get-lps"
import { Button } from "@/components/ui/button"
import { Player } from "@lottiefiles/react-lottie-player"

export function Loader({ children }: React.PropsWithChildren) {
  const user = useGetUser()
  const learningPaths = useGetLps()
  const [loading] = useDebounceValue(user.isPending || learningPaths.isPending, 1000)

  const isError = user.isError || learningPaths.isError
  const isSuccess = user.isSuccess && learningPaths.isSuccess
  const refetch = [user.refetch, learningPaths.refetch]

  if (loading) {
    return (
      <div className="justify-cente flex h-full w-full items-center justify-center">
        <div className="rounded-lgp-6 flex max-w-md flex-col items-center justify-center">
          <Player src="./animations/books.json" autoplay loop className="w-3/4 max-w-sm" />
          <p className="ml-12 mt-12 p-4 text-center text-xl font-medium text-gray-500">
            Estamos cargando algunos datos...
          </p>
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-gray-100">
        <div className="rounded-lgp-6 flex max-w-md flex-col items-center justify-center">
          <p className="font-mediu mb-4 text-lg">Ha ocurrido un error</p>
          <Button onClick={() => refetch.forEach((r) => r())}>Reintentar</Button>
        </div>
      </div>
    )
  }

  return isSuccess ? children : null
}
