interface Props {
  progress: number
  className?: string
  withoutPercentage?: boolean
  circleSize?: number
}

const CircularProgress = ({ progress, className, withoutPercentage, circleSize = 40 }: Props) => {
  const radius = 40
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (progress / 100) * circumference

  return (
    <svg className={className} viewBox="0 0 100 100">
      <circle
        className="stroke-current text-gray-200"
        strokeWidth="10"
        cx="50"
        cy="50"
        r={circleSize}
        fill="transparent"
      ></circle>
      <circle
        className="stroke-current text-orange-400"
        strokeWidth="10"
        strokeLinecap="round"
        cx="50"
        cy="50"
        r={circleSize}
        fill="transparent"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform="rotate(-90 50 50)"
      ></circle>

      <text
        className="text-lg font-medium"
        x="50"
        y="50"
        font-size="12"
        text-anchor="middle"
        alignment-baseline="middle"
      >
        {progress} {withoutPercentage ? "" : "%"}
      </text>
    </svg>
  )
}

export default CircularProgress
