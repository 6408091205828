import { client } from "@/config/client"

interface MetricsResponse {
  total: number
  totalPremium: number
}

export function metrics() {
  return client.get<MetricsResponse>("/v1/admins/metrics")
}
