import { client } from "@/config/client"

export interface CreateStripeSessionData {
  productId: string
}

export interface CreateStripeSessionResponse {
  sessionId: string
}

export function create(data: CreateStripeSessionData) {
  return client.post<CreateStripeSessionResponse, CreateStripeSessionData>(
    "/v1/stripe-sessions",
    data,
  )
}
