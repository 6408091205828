import { type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "tools",
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: ToolsDashboardPage } = await import("@/pages/tools/dashboard")
        return { Component: ToolsDashboardPage }
      },
    },
    {
      path: "mindmaps",
      children: [
        {
          index: true,
          lazy: async () => {
            const { default: MindMapsPage } = await import("@/pages/tools/mindmaps/dashboard")
            return { Component: MindMapsPage }
          },
        },
        {
          path: "new",
          lazy: async () => {
            const { default: NewMindMapPage } = await import("@/pages/tools/mindmaps/new")
            return { Component: NewMindMapPage }
          },
        },
        {
          path: "generated",
          lazy: async () => {
            const { default: GeneratedMindMapPage } = await import(
              "@/pages/tools/mindmaps/generated"
            )
            return { Component: GeneratedMindMapPage }
          },
        },
        {
          path: ":mindmapId",
          lazy: async () => {
            const { default: MindmapPage } = await import("@/pages/tools/mindmaps/mindmap")
            return { Component: MindmapPage }
          },
        },
      ],
    },
  ],
}

export default routes
