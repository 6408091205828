import { useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import useLoginWithGoogle from "@/modules/auth/queries/use-login-with-google"

export default function GoogleAuth() {
  const location = useLocation()
  const [error, setError] = useState(false)
  const loginWithGoogle = useLoginWithGoogle()

  useEffect(() => {
    auth()
  }, [])

  async function auth() {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get("code") ?? ""
    try {
      await loginWithGoogle.mutateAsync(code)
    } catch {
      setError(true)
    }
  }

  if (error || loginWithGoogle.isError) {
    return <Navigate to="/login" />
  }

  if (loginWithGoogle.isSuccess && !loginWithGoogle.data.data.user.wasOnboarded) {
    return <Navigate to="/onboarding" replace />
  }

  return loginWithGoogle.isSuccess ? <Navigate to="/dashboard" /> : null
}
