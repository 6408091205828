import { QUESTION_TYPE } from "@/services/api/learning-paths/themes/subthemes/get"
import { useState } from "react"
import { useLearningSession } from "../stores/use-learning-session"
import type { SessionQuestion } from "@/services/api/learning-paths/get"

interface UseQuestionTypeProps {
  currentQuestion: SessionQuestion
}

export const useQuestionTypes = ({ currentQuestion }: UseQuestionTypeProps) => {
  const [openAnswer, setOpenAnswer] = useState<string>("")
  const [selectedOption, setSelectedOption] = useState<number | null>(null)
  const { learningSession, setLearningSession } = useLearningSession()
  const { answer } = currentQuestion ?? {}
  const currentAnswer = learningSession.answers[learningSession.step - 1]

  function checkCloseAnswer(option: string, index: number) {
    const existingIndex = learningSession.answers.findIndex((answer) => answer.index === index)

    const newAnswer = {
      index,
      correct: option === answer,
      answer: option,
    }
    if (existingIndex >= 0) {
      const updatedAnswers = learningSession.answers.map((answer, i) =>
        i === existingIndex ? newAnswer : answer,
      )
      setLearningSession({
        ...learningSession,
        answers: updatedAnswers,
      })
    } else {
      setLearningSession({
        ...learningSession,
        answers: [...learningSession.answers, newAnswer],
      })
    }
  }

  function checkTrueFalseAnswer(option: string, index: number) {
    const isCorrect = option.toLowerCase() === answer.toLowerCase()
    const existingIndex = learningSession.answers?.findIndex((item) => item.index === index)
    if (existingIndex >= 0) {
      const updatedAnswers = learningSession.answers.map((item, idx) =>
        idx === existingIndex ? { ...item, correct: isCorrect, answer: option } : item,
      )
      setLearningSession({
        ...learningSession,
        answers: updatedAnswers,
      })
    } else {
      setLearningSession({
        ...learningSession,
        answers: [
          ...(learningSession.answers ?? []),
          {
            index,
            correct: isCorrect,
            answer: option,
          },
        ],
      })
    }
  }

  const checkOpenAnswer = (answer: string, index: number, type?: QUESTION_TYPE) => {
    if (type === QUESTION_TYPE.OPEN_ENDED || type === QUESTION_TYPE.ANALYSIS) {
      setLearningSession({
        ...learningSession,
        answers: [
          ...learningSession.answers,
          {
            index,
            answer,
          },
        ],
      })
    }
  }

  function handleOptionClick(option: string, index: number, optionIndex: number) {
    if (currentQuestion.type === QUESTION_TYPE.TRUE_FALSE) checkTrueFalseAnswer(option, index)
    if (currentQuestion.type === QUESTION_TYPE.MULTIPLE_CHOICE) checkCloseAnswer(option, index)
    setSelectedOption(optionIndex)
  }

  return {
    answer,
    openAnswer,
    selectedOption,
    currentAnswer,
    setSelectedOption,
    setOpenAnswer,
    checkTrueFalseAnswer,
    checkCloseAnswer,
    checkOpenAnswer,
    handleOptionClick,
  }
}
