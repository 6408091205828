import { useNavigate } from "react-router-dom"
import { isApproved, isRegular } from "../../utils/score"
import { FileText } from "lucide-react"

const CompletedLevelButton = ({
  score,
  levelId,
  lastCompletedSessionId,
}: {
  score: number
  levelId: string
  lastCompletedSessionId: string
}) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("review", {
      state: { levelId, lastCompletedSessionId },
    })
  }

  const colorsInfoByScore = {
    bad: {
      bg: "bg-red-100 shadow-custom-red-shadow",
      text: "text-red-500",
    },
    medium: {
      bg: "bg-amber-100 shadow-custom-amber-shadow",
      text: "text-amber-500",
    },
    great: {
      bg: "bg-emerald-100 shadow-custom-green-shadow",
      text: "text-emerald-500",
    },
  }

  const getColorByScore = (): { bg: string; text: string } => {
    if (isApproved(score)) return colorsInfoByScore.great
    if (isRegular(score)) return colorsInfoByScore.medium
    return colorsInfoByScore.bad
  }

  return (
    <div
      className="flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[rgba(242,242,247,1)] p-2 shadow-custom-shadow"
      onClick={handleClick}
    >
      <div
        className={`flex size-full items-center ${getColorByScore().bg} justify-center rounded-full`}
      >
        <FileText className={`${getColorByScore().text}`} />
      </div>
    </div>
  )
}

export default CompletedLevelButton
