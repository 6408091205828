import { api } from "@/services/api"
import type { CreateFeedbackData } from "@/services/api/diagnoses/create-feedback"
import { useMutation } from "@tanstack/react-query"

export const useCreateFeedback = () => {
  const mutation = useMutation({
    mutationKey: ["create-feedback"],
    mutationFn: async (data: CreateFeedbackData) => api.diagnoses.createFeedback(data),
  })
  return mutation
}
