import { useMutation } from "@tanstack/react-query"
import { useAuth } from "../stores"
import { client } from "@/config/client"

interface LoginAsUserResponse {
  token: string
  user: {
    id: string
    name: string
    email: string
    avatar: string
    wasOnboarded: boolean
  }
}

export default function useLoginAsUser() {
  const { logIn } = useAuth()

  const query = useMutation({
    mutationFn: (email: string) => {
      return client.post<LoginAsUserResponse, { email: string }>(
        import.meta.env.VITE_BASE_API_URL + "/v1/auths/as_user",
        {
          email,
        },
      )
    },
    onSuccess: (data) => {
      logIn(data.data.token, data.data.user)
    },
  })

  return query
}
