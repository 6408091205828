import { Button } from "@/components/button"
import { TabsContent } from "@/components/ui/tabs"
import { Textarea } from "@/components/ui/textarea"
import { useLearningPaths } from "@/modules/app/stores/use-lp-store"
import useCreateChallengeMutation from "@/modules/challenges/queries/use-create-challenge-mutation"
import { CHALLENGE_TYPE, type InputChallenge } from "@/services/api/challenges/create-challenge"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"

const CreateChallengeByIATab = () => {
  const [text, setText] = useState<string>("")
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const mutation = useCreateChallengeMutation()
  const { selectedLearningPath } = useLearningPaths()
  const { isPending, mutateAsync } = mutation

  const handleOnGenerate = async () => {
    try {
      const payload: InputChallenge = {
        type: CHALLENGE_TYPE.INPUT,
        input: text.trim(),
        learningPathId: selectedLearningPath.id,
      }

      await mutateAsync(payload)

      queryClient.invalidateQueries({ queryKey: ["userChallenges"] })
      navigate("/challenges")
      toast.success("Desafío creado correctamente")
    } catch {
      toast.error("Ha ocurrido un error al crear el desafío mediante IA", {
        description: "Intenta de nuevo más tarde",
      })
    }
  }

  const handleOnChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }

  return isPending ? (
    <div className="fixed left-0 top-0 flex size-full flex-col items-center justify-center gap-6 bg-amber-50">
      <div className="h-20 w-20 animate-spin rounded-full border-8 border-transparent border-t-orange-500 duration-500"></div>
      <span className="text-sm font-medium text-gray-500">Creando desafío</span>
    </div>
  ) : (
    <TabsContent value="ia" className="m-0 flex size-full flex-col">
      <article className="size-full">
        <span className="mb-2 inline-block text-sm font-medium">Ingresá un tema</span>
        <Textarea
          className="h-40 max-h-80 w-full resize-none overflow-y-auto overflow-x-hidden rounded-[6px] border border-neutral-300"
          value={text}
          onChange={handleOnChangeText}
          disabled={isPending}
        />
      </article>

      <div className="py-6">
        <Button
          type="button"
          onClick={handleOnGenerate}
          className="w-full rounded-[8px]"
          disabled={text.trim() === "" || isPending}
          loading={isPending}
        >
          Generar desafío
        </Button>
      </div>
    </TabsContent>
  )
}

export default CreateChallengeByIATab
