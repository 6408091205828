import { type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "diagnoses",
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: DiagnosesPage } = await import("@/pages/diagnoses")
        return { Component: DiagnosesPage }
      },
    },
  ],
}

export default routes
