import { api } from "@/services/api"
import { useQuery } from "@tanstack/react-query"
import { useUser } from "../stores/use-user"
import { useEffect } from "react"

export default function useGetUser() {
  const user = useUser()
  const query = useQuery({
    queryKey: ["get-user"],
    queryFn: async () => api.user.info.get(),
    select: (data) => data.data,
  })

  useEffect(() => {
    if (query.isSuccess) {
      user.setUser(query.data.user)
    }
  }, [query.status, query.data])

  return query
}
