import React from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { buttonVariants } from "@/components/ui/button"
import { Link } from "react-router-dom"

interface Props {
  open: boolean
  onToggle: () => void
}

export const GetPremiumModal: React.FC<Props> = ({ open, onToggle }) => {
  return (
    <Dialog open={open} onOpenChange={onToggle}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle> Te quedaste sin vidas </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center justify-center gap-4">
          <span className="font-xl text-md font-light">
            Pasate a nuestro plan premium y desbloqueá todo el potencial de Tich AI 🦾{" "}
          </span>
          <Link
            to="/planes"
            className={buttonVariants({
              variant: "default",
            })}
          >
            {" "}
            Quiero ser premium
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  )
}
