import { buttonVariants } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { SparklesIcon } from "lucide-react"
import { Link } from "react-router-dom"

interface TitoButtonProps {
  className?: string
  iconClassName?: string
}

export default function TitoButton({ className, iconClassName }: TitoButtonProps) {
  return (
    <Link
      to="/tito"
      className={cn(
        buttonVariants({
          size: "icon",
        }),
        "bg-gradient-tito size-14 rounded-full bg-blend-multiply max-[425px]:size-11",
        "shadow-[0px_0px_1.56px_0px_rgba(255,255,255,0.1)_inset,0px_0px_14px_0px_rgba(0,0,0,0.2),0px_0px_3.11px_0px_rgba(0,0,0,0.25)]",
        "backdrop-blur-[62.22px]",
        className,
      )}
    >
      <SparklesIcon className={cn("!size-7 !text-primary-200", iconClassName)} />
      <span className="sr-only">Tito</span>
    </Link>
  )
}
