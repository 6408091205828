import LearningPath from "@/modules/learning-paths/components/learning-path"
import { useParams } from "react-router-dom"

export default function LearningPathShowPage() {
  const { id = "" } = useParams<{ id: string }>()
  return (
    <section className="flex h-full w-full p-12">
      <LearningPath id={id} />
    </section>
  )
}
