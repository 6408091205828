import { api } from "@/services/api"
import { useMutation } from "@tanstack/react-query"

export default function useDeleteSubtheme(id: string) {
  const query = useMutation({
    mutationKey: ["delete-subtheme", id],
    mutationFn: async () => api.learnignPaths.deleteSubtheme(id),
  })

  return query
}
