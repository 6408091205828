import { api } from "@/services/api"
import { useMutation } from "@tanstack/react-query"

export default function useCreateLp() {
  const query = useMutation({
    mutationKey: ["create-learnign-path"],
    mutationFn: async (name: string) => api.learnignPaths.create({ name }),
  })

  return query
}
