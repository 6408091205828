import { Button } from "@/components/ui/button"
import { Check, Loader2, XIcon } from "lucide-react"
import Latex from "react-latex"
import type { SessionQuestion } from "@/services/api/learning-paths/get"
import { useQuestionTypes } from "@/modules/learning-paths/hooks/use-question-types"
import "katex/dist/katex.min.css"
import { Card, CardContent } from "@/components/ui/card"
import { cn } from "@/lib/utils"

interface MultipleChoiceQuestionProps {
  currentQuestion: SessionQuestion
  isLoading: boolean
  handleNextStep: () => Promise<void>
  handleFeedback: () => Promise<void>
}

export default function MultipleChoiceQuestion({
  currentQuestion,
  isLoading,
  handleNextStep,
  handleFeedback,
}: MultipleChoiceQuestionProps) {
  const { selectedOption, currentAnswer, handleOptionClick, setSelectedOption } = useQuestionTypes({
    currentQuestion,
  })

  return (
    <div className="mt-12 grid w-full gap-4">
      <div className="flex items-center gap-4">
        <p className="text-lg font-medium">
          <Latex>{currentQuestion.question}</Latex>{" "}
        </p>
      </div>
      <div className="flex flex-col gap-4">
        {currentQuestion.options.map((option: string, index: number) => (
          <div
            key={`option-${index}`}
            className={`flex cursor-pointer items-center justify-between gap-4 rounded-xl border p-4 shadow-lg ${
              index === selectedOption ? "bg-primary-300 font-semibold text-white" : ""
            }`}
            onClick={() => handleOptionClick(option, currentQuestion.index, index)}
          >
            <p>
              <Latex>{option}</Latex>
            </p>
            {currentAnswer?.score !== undefined && (
              <>
                {currentAnswer?.score >= 0 && (
                  <div>
                    {option === currentQuestion.answer ? (
                      <Check className="rounded-full bg-green-500 p-2 text-white" size={32} />
                    ) : (
                      <XIcon className="rounded-full bg-red-500 p-2 text-white" size={32} />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
      {currentAnswer && (
        <div className="flex flex-col items-end gap-8">
          <>
            {currentAnswer?.score !== undefined && currentAnswer?.score >= 0 ? (
              <Button
                className="text-md w-fit"
                onClick={() => {
                  setSelectedOption(null)
                  handleNextStep()
                }}
              >
                Siguiente
              </Button>
            ) : (
              <Button disabled={isLoading} className="text-md w-fit" onClick={handleFeedback}>
                {isLoading && <Loader2 className="mr-2 animate-spin" />}
                Verificar
              </Button>
            )}

            {currentAnswer?.score !== undefined && currentAnswer?.score >= 0 && (
              <Card className="w-full">
                <CardContent className="space-y-4 px-2 py-4">
                  <section
                    className={cn(
                      "animate-fade-in w-full border-l-4 p-4 transition-colors",
                      currentAnswer?.score === 10
                        ? "border-emerald-300 text-emerald-500"
                        : "border-red-300 text-red-500",
                    )}
                  >
                    <h2 className="text-lg font-medium">
                      {currentAnswer?.score === 10 ? "¡Excelente trabajo!" : "Revisa tu respuesta"}
                    </h2>
                    <p className="mt-2 text-sm leading-relaxed">{currentAnswer?.feedback}</p>
                  </section>
                </CardContent>
              </Card>
            )}
          </>
        </div>
      )}
    </div>
  )
}
