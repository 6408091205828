import { api } from "@/services/api"
import { useMutation } from "@tanstack/react-query"

export default function useDeleteTheme(id: string) {
  const query = useMutation({
    mutationKey: ["delete-theme", id],
    mutationFn: async () => api.learnignPaths.deleteTheme(id),
  })

  return query
}
