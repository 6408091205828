import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import "./theme/theme.css"
import "./globals.css"
import App from "./app/app"

async function main() {
  console.info("🚢 Release version:", import.meta.env.VITE_RELEASE_VERSION)
  const root = document.getElementById("root")
  if (root) {
    createRoot(root).render(
      <StrictMode>
        <App />
      </StrictMode>,
    )
  }
}

main()
