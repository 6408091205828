import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { CreditCard, Users } from "lucide-react"

interface AdminMetricsProps {
  total: number
  totalPremium: number
}

export default function AdminMetrics({ total, totalPremium }: AdminMetricsProps) {
  return (
    <div className="grid w-full grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
      <Card className="w-full">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="truncate text-sm font-medium">Usuarios</CardTitle>
          <Users className="h-5 w-5 text-primary" strokeWidth={2} />
        </CardHeader>
        <CardContent className="pt-0">
          <div className="text-2xl font-bold">{total.toLocaleString()}</div>
          <p className="mt-1 text-xs text-gray-500">Total de usuarios registrados</p>
        </CardContent>
      </Card>
      <Card className="w-full">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="truncate text-sm font-medium">Suscriptos</CardTitle>
          <CreditCard className="h-5 w-5 text-primary" strokeWidth={2} />
        </CardHeader>
        <CardContent className="pt-0">
          <div className="text-2xl font-bold">{totalPremium.toLocaleString()}</div>
          <p className="mt-1 text-xs text-gray-500">Total de usuarios suscriptos</p>
        </CardContent>
      </Card>
    </div>
  )
}
