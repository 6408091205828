import { Play } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { useNoLifeAlert } from "@/modules/app/stores/use-no-life-alert"
import useCanContinueLearning from "@/modules/user/hooks/use-can-continue-learning"

const AvailableLevelButton = ({ levelId }: { levelId: string }) => {
  const navigate = useNavigate()
  const canContinueLearning = useCanContinueLearning()
  const { setIsOpen: openNoLifeAlert } = useNoLifeAlert()

  const handleClick = () => {
    if (canContinueLearning) {
      navigate(`/challenges/${levelId}`)
    } else {
      openNoLifeAlert(true)
    }
  }

  return (
    <div
      className="flex h-full w-full cursor-pointer items-center justify-center rounded-full p-2"
      onClick={handleClick}
    >
      <div className="flex h-full w-full items-center justify-center rounded-full bg-[rgba(255,237,213,1)] shadow-custom-orange-shadow">
        <Play className="text-orange-500" />
      </div>
    </div>
  )
}

export default AvailableLevelButton
