import { useUser } from "@/modules/user/stores"
import { Navigate } from "react-router-dom"

export interface ProtectedRouteProps {
  redirect: string
  roles: string[]
  children: React.ReactNode
}

export default function HasRoleRouter({ redirect, roles, children }: ProtectedRouteProps) {
  const { user } = useUser()
  if (!roles.includes(user.role)) {
    return <Navigate to={redirect} replace />
  }

  return children
}
