import { client } from "@/config/client"
import type { Question } from "./create-level-session"

export interface AnswerSessionQuestionParams {
  levelId: string
  sessionId: string
  questionId: string
  body: {
    optionIndex: number
    elapsedResponseTimeInSeconds: number
  }
}

export interface AnswerSessionQuestionResponse {
  feedback: {
    explanation: string
    correctOptionIndex: number
  }
  question: Question | null // next_question is null when the quiz is finished
}

const answerSessionQuestion = (
  _params: AnswerSessionQuestionParams,
): Promise<AnswerSessionQuestionResponse> => {
  const { levelId, sessionId, questionId, body } = _params

  return client
    .post<
      AnswerSessionQuestionResponse,
      AnswerSessionQuestionParams["body"]
    >(`/v1/challenge-levels/${levelId}/session/${sessionId}/question/${questionId}/answers`, body)
    .then((resp) => resp.data)
}

export default answerSessionQuestion
