import { type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "my-profile",
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: ProfilePage } = await import("@/pages/user/my-profile")
        return { Component: ProfilePage }
      },
    },
  ],
}

export default routes
