import { api } from "@/services/api"
import {
  ProcessSessionErrors,
  type ProcessSessionData,
} from "@/services/api/documents/diagnoses/process-session"
import type { ClientError } from "@educabot/fetcher"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "sonner"

export const useProcessSession = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (data: ProcessSessionData) => api.documents.diagnoses.processSession(data),
    onSuccess: () => {
      toast.success("Sesión creada correctamente")
    },
    onError: (error: ClientError) => {
      if (error.code === ProcessSessionErrors.U1 && error.statusCode === 403) {
        toast.error(error.message)
        return
      }
      toast.error("Demasiada información", {
        description:
          "Para tener buenos resultados es importante ser específicos; Por favor seleccioná un intervalo menor de páginas en el seleccionador. (No hay un número estandar, cada documento varía en su cantidad de texto por página).",
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["get-user"] })
    },
  })

  return mutation
}
