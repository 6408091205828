import { useMemo } from "react"

export interface UseGoogleAuthUrlProps {
  clientId: string
  redirectUri: string
  scope?: string
  responseType?: string
  prompt?: string
  accessType?: string
}

const useGoogleAuthUrl = ({
  clientId,
  redirectUri,
  scope = "openid profile email",
  responseType = "code",
  prompt = "select_account",
  accessType = "offline",
}: UseGoogleAuthUrlProps): string => {
  const googleAuthUrl = useMemo(() => {
    const url = new URL("https://accounts.google.com/o/oauth2/v2/auth")
    url.searchParams.append("client_id", clientId)
    url.searchParams.append("redirect_uri", redirectUri)
    url.searchParams.append("scope", scope)
    url.searchParams.append("response_type", responseType)
    url.searchParams.append("prompt", prompt)
    url.searchParams.append("access_type", accessType)
    return url.toString()
  }, [clientId, redirectUri, scope, responseType, prompt, accessType])

  return googleAuthUrl
}

export default useGoogleAuthUrl
