import type { Question } from "@/services/api/challenges/create-level-session"
import { useEffect, useState } from "react"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import useAnswerSessionQuestionMutation from "../../queries/use-answer-session-question-mutation"
import GameHeader from "./game-header"
import DifficultyLabel from "./difficulty-label"
import FloatingGameButton from "./floating-game-button"
import getDifferenceInSeconds from "../../utils/get-difference-in-seconds"
import getLetterByIndex from "../../utils/get-letter-by-index"
import ResultDrawer from "./result-drawer"
import { toast } from "sonner"

interface GameLayoutProps {
  levelId: string
  session: {
    id: string
    numberOfQuestions: number
    question: Question
  }
}

const GameLayout = ({ session, levelId }: GameLayoutProps) => {
  const { id: sessionId, numberOfQuestions, question: initialQuestion } = session
  const [question, setQuestion] = useState<Question>(initialQuestion)
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)

  const {
    isPending,
    mutate,
    data: result,
    reset,
  } = useAnswerSessionQuestionMutation({
    onError: () => {
      toast.error("Algo salió mal")
    },
    onSuccess: () => {
      setIsOpenDrawer(true)
    },
  })

  const [selectedOption, setSelectedOption] = useState({
    initialTimeInMiliseconds: new Date().getTime(),
    selectedOption: {
      id: "",
      index: 0,
      label: "",
      option: "",
    },
  })

  const resetGame = () => {
    setIsOpenDrawer(false)
    reset()
    setSelectedOption({
      initialTimeInMiliseconds: new Date().getTime(),
      selectedOption: {
        id: "",
        index: 0,
        label: "",
        option: "",
      },
    })
  }

  useEffect(() => {
    resetGame()
  }, [question])

  const isDisabled = selectedOption.selectedOption.id === ""

  const setNewQuestion = (newQuestion: Question): void => {
    setQuestion(newQuestion)
  }

  const onVerifyAnswer = () => {
    const endTimeInMiliseconds = new Date().getTime()

    const elapsedResponseTimeInSeconds = getDifferenceInSeconds(
      selectedOption.initialTimeInMiliseconds,
      endTimeInMiliseconds,
    )

    const body = {
      levelId: levelId,
      sessionId: sessionId,
      questionId: question.id,
      body: {
        optionIndex: selectedOption.selectedOption.index,
        elapsedResponseTimeInSeconds: elapsedResponseTimeInSeconds,
      },
    }

    mutate(body)
  }

  const formattedOptions = question.options.map((option, i) => ({
    id: i.toString(),
    index: i,
    label: getLetterByIndex(i),
    option,
  }))

  const onValueChange = (newOptionId: string) => {
    const newSelectedOption = formattedOptions.find((option) => option.id === newOptionId)
    if (!newSelectedOption) return

    setSelectedOption({
      ...selectedOption,
      selectedOption: newSelectedOption,
    })
  }

  const handleOnOpenChange = () => {
    setIsOpenDrawer(!isOpenDrawer)
  }

  return (
    <>
      <div className="flex h-full w-full flex-col items-center overflow-hidden">
        <GameHeader
          currentQuestionNumber={question.currentQuestionNumber}
          numberOfQuestions={numberOfQuestions}
          isDisabled={isPending}
        />

        <div className="mx-auto w-full max-w-[800px] overflow-y-auto p-3">
          <div className="mb-6 flex w-full flex-col items-start gap-2">
            <span className="flex select-none items-center justify-center gap-1 self-end rounded-[99px] bg-orange-100 px-3 py-1 text-sm font-semibold capitalize text-orange-600">
              <DifficultyLabel difficulty={question.difficulty} />
            </span>

            <p className="text-[20px] font-bold">
              Pregunta {question.currentQuestionNumber} de {numberOfQuestions}
            </p>
            <p className="text-[20px] font-bold">{question.question}</p>
          </div>

          <RadioGroup
            value={selectedOption.selectedOption.id}
            onValueChange={onValueChange}
            className="flex w-full flex-col gap-4 pb-[75px] md:pb-[40px]"
            disabled={isPending}
          >
            {formattedOptions.map((option, i) => {
              const isSelectedOption = selectedOption.selectedOption.id === option.id

              return (
                <div
                  className={`w-full rounded-lg border ${isSelectedOption ? "border-orange-300 bg-orange-50" : "border-neutral-300 bg-transparent"} cursor-pointer`}
                  key={i}
                >
                  <RadioGroupItem value={option.id} id={option.id} className="hidden" />
                  <Label
                    htmlFor={option.id}
                    className={`flex ${isPending ? "cursor-default" : "cursor-pointer"} items-start gap-2 p-4`}
                  >
                    <span
                      className={`shrink-0 text-[14px] font-bold uppercase ${isSelectedOption ? "text-orange-400" : "text-gray-500"}`}
                    >
                      {option.label}.
                    </span>
                    <p className="w-full text-[16px] font-normal">{option.option}</p>
                  </Label>
                </div>
              )
            })}
          </RadioGroup>
        </div>

        <FloatingGameButton
          isLoading={isPending}
          isDisabled={isDisabled}
          onClickBtn={onVerifyAnswer}
        />
      </div>
      {result && (
        <ResultDrawer
          isOpen={isOpenDrawer}
          handleOnOpenChange={handleOnOpenChange}
          result={result}
          selectedOptionIndex={selectedOption.selectedOption.index}
          setNewQuestion={setNewQuestion}
          dataForReview={{ sessionId, levelId }}
        />
      )}
    </>
  )
}

export default GameLayout
