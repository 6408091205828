import type { Level } from "@/services/api/challenges/get-user-challenges"
import isEven from "../../utils/is-even"
import isMultiplotOf4 from "../../utils/is-multiplot-of-4"
import ChallengeLevelsMapItem from "./challenge-levels-map-item"

interface ChallengeLevelsMapProps {
  levels: Level[]
}

const ChallengeLevelsMap = ({ levels }: ChallengeLevelsMapProps) => {
  const sortedLevels = levels.sort((a, b) => a.order - b.order)

  return sortedLevels.length > 0 ? (
    <div className="mx-auto grid w-fit max-w-full auto-rows-[100px] grid-cols-[repeat(3,_100px)] items-center justify-items-center gap-0 overflow-hidden">
      {sortedLevels.map((level, index) => (
        <Row level={level} index={index} totalLevels={sortedLevels.length} key={level.id} />
      ))}
    </div>
  ) : (
    <div>
      <p className="select-none text-center text-sm text-gray-400">
        ¡Este desafío no tiene niveles!
      </p>
    </div>
  )
}

interface RowProps {
  level: Level
  index: number
  totalLevels: number
}

const getColumn = (index: number) => {
  if (index === 0) {
    return 1
  } else if (!isEven(index)) {
    return 2
  } else if (isEven(index) && !isMultiplotOf4(index)) {
    return 3
  } else {
    return 1
  }
}

const Row = ({ level, index, totalLevels }: RowProps) => {
  const isAMiddleItem = !isEven(index)
  const prevItemIndex = index - 1
  const nextItemIndex = index + 1
  const isThereANextItem = nextItemIndex < totalLevels

  // Left line.
  const isThereAPrevItemOnTheLeft = prevItemIndex === 0 || isMultiplotOf4(prevItemIndex)
  const isThereANextItemOnTheLeft = isThereANextItem && isMultiplotOf4(nextItemIndex)

  // Right line.
  const isThereAPrevItemOnTheRight = isEven(prevItemIndex) && !isMultiplotOf4(prevItemIndex)
  const isThereANextItemOnTheRight = isThereANextItem && isEven(nextItemIndex)

  return (
    <>
      {/* Left line. */}
      {isAMiddleItem && (isThereAPrevItemOnTheLeft || isThereANextItemOnTheLeft) && (
        <div
          className={`flex size-full select-none text-white ${isThereAPrevItemOnTheLeft ? "items-start justify-end" : "items-end justify-end"}`}
          style={{
            gridColumn: 1,
            gridRow: index + 1,
          }}
        >
          <img
            src={"https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/elipse-level.png"}
            alt="elipseLevel"
            className={`size-[80%] ${isThereAPrevItemOnTheLeft ? "translate-x-[28px] translate-y-[-25px]" : "translate-x-[22px] translate-y-[30px] rotate-[87deg]"}`}
          />
        </div>
      )}

      <div
        className="relative z-10 size-[72px] select-none"
        style={{
          gridColumn: getColumn(index),
          gridRow: index + 1,
        }}
      >
        <ChallengeLevelsMapItem level={level} />
      </div>

      {/* Right line. */}
      {isAMiddleItem && (isThereAPrevItemOnTheRight || isThereANextItemOnTheRight) && (
        <div
          className={`flex size-full select-none text-white ${isThereAPrevItemOnTheRight ? "items-start justify-start" : "items-end justify-end"}`}
          style={{
            gridColumn: 3,
            gridRow: index + 1,
          }}
        >
          <img
            src={"https://storage.googleapis.com/educabot-aec-cdn-1/tich/images/elipse-level.png"}
            alt="elipseLevel"
            className={`size-[80%] ${isThereAPrevItemOnTheRight ? "translate-x-[-26px] translate-y-[-28px] rotate-[-90deg]" : "translate-x-[-43px] translate-y-[36px] rotate-[-180deg]"}`}
          />
        </div>
      )}
    </>
  )
}

export default ChallengeLevelsMap
