import { Navigate, Outlet, type RouteObject } from "react-router-dom"
import LearningPathsPage from "@/pages/learning-paths"
import LearningPathShowPage from "@/pages/learning-paths/show/id"
import LearningPathEditPage from "@/pages/learning-paths/edit/id"
import SubthemeSessionPage from "@/pages/learning-paths/subtheme/session-id"
import CreatePlanningPage from "@/pages/learning-paths/planning/create"

const routes: RouteObject = {
  path: "learning-paths",
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <LearningPathsPage />,
    },
    {
      path: ":id",
      element: <LearningPathShowPage />,
    },
    {
      path: "planning/create",
      element: <CreatePlanningPage />,
    },
    {
      path: ":id/subtheme/:sessionId",
      element: <SubthemeSessionPage />,
    },
    {
      path: "edit/:id",
      element: <LearningPathEditPage />,
    },
    {
      path: "*",
      element: <Navigate to="subjects" replace />,
    },
  ],
}

export default routes
