import { useMutation } from "@tanstack/react-query"
import { useAuth } from "../stores"
import { client } from "@/config/client"

interface LoginWithGoogleResponse {
  token: string
  user: {
    id: string
    name: string
    email: string
    avatar: string
    wasOnboarded: boolean
  }
}

export default function useLoginWithGoogle() {
  const { logIn } = useAuth()

  const query = useMutation({
    mutationFn: (code: string) => {
      return client.post<LoginWithGoogleResponse, { code: string; redirectUri: string }>(
        "/v1/auths/google",
        {
          code,
          redirectUri: import.meta.env.VITE_GOOGLE_AUTH_REDIRECT_URI,
        },
      )
    },
    onSuccess: (data) => {
      logIn(data.data.token, data.data.user)
    },
  })

  return query
}
