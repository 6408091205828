import { cn } from "@/lib/utils"
import { Slot } from "@radix-ui/react-slot"
import { type VariantProps } from "class-variance-authority"
import * as React from "react"
import { Loader } from "../loader"
import { variants } from "./button-variants"

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof variants> {
  asChild?: boolean
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  loading?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      iconLeft,
      iconRight,
      loading = false,
      disabled,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button"

    return (
      <Comp
        className={cn(
          variants({ variant, size, className }),
          "inline-flex items-center",
          (iconLeft || iconRight) && children ? "gap-2" : "",
          loading,
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {loading ? (
          <>
            <Loader className="mr-2" color="white" size="xs" />
            {children}
          </>
        ) : (
          <>
            {iconLeft}
            {children}
            {iconRight}
          </>
        )}
      </Comp>
    )
  },
)

Button.displayName = "Button"
