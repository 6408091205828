import CollapsableChallengeListItem from "./collapsable-challenge-list-item"
import type { UseQueryResult } from "@tanstack/react-query"
import type { getUserChallengesResponse } from "@/services/api/challenges/get-user-challenges"

interface CollapsableChallengeListProps {
  challengesQuery: UseQueryResult<getUserChallengesResponse, Error>
}

const CollapsableChallengeList = ({ challengesQuery }: CollapsableChallengeListProps) => {
  const { isLoading, isError, data, error } = challengesQuery

  if (isLoading) {
    return <div className="size-sm text-center text-sm">Cargando...</div>
  }

  if (isError) {
    return <div className="size-sm text-center text-sm">{error.message}</div>
  }

  return data && data.challenges.length > 0 ? (
    <div className="flex flex-col gap-4">
      {data.challenges.map((challenge) => (
        <CollapsableChallengeListItem challenge={challenge} key={challenge.id} />
      ))}
      {/* TO DO: Pagination component here */}
    </div>
  ) : (
    <div>
      <p className="select-none p-4 py-8 text-center text-gray-600">¡No tienes desafíos aún!</p>
    </div>
  )
}

export default CollapsableChallengeList
