const scoreInfo = {
  approve: {
    min: 70,
    max: 100,
  },
  regular: {
    min: 50,
    max: 69,
  },
  bad: {
    min: 0,
    max: 49,
  },
}

export const isApproved = (number: number): boolean => {
  return number >= scoreInfo.approve.min && number <= scoreInfo.approve.max
}

export const isRegular = (number: number): boolean => {
  return number >= scoreInfo.regular.min && number <= scoreInfo.regular.max
}

export const isBad = (number: number): boolean => {
  return number >= scoreInfo.bad.min && number <= scoreInfo.bad.max
}
