import { api } from "@/services/api"
import type { CreateThemeData } from "@/services/api/documents/themes/create"
import { useMutation } from "@tanstack/react-query"

export default function useCreateTheme() {
  const mutation = useMutation({
    mutationKey: ["create-theme"],
    mutationFn: async (data: CreateThemeData) => api.documents.themes.create(data),
  })
  return mutation
}
