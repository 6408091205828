import { client } from "@/config/client"

interface EditChallengeParams {
  challengeId: string
  body: {
    name: string
  }
}

const editChallenge = ({ challengeId, body }: EditChallengeParams) => {
  return client.put(`/v1/challenges/${challengeId}`, body)
}

export default editChallenge
