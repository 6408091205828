import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer"
import { Button } from "@/components/ui/button"
import type { AnswerSessionQuestionResponse } from "@/services/api/challenges/answer-session-question"
import { CircleCheck, CircleX, ChevronRight } from "lucide-react"
import getLetterByIndex from "../../utils/get-letter-by-index"
import { useNavigate } from "react-router-dom"
import type { Question } from "@/services/api/challenges/create-level-session"
import { useQueryClient } from "@tanstack/react-query"

interface ResultDrawerProps {
  isOpen: boolean
  handleOnOpenChange: () => void
  result: AnswerSessionQuestionResponse
  selectedOptionIndex: number
  setNewQuestion: (newQuestion: Question) => void
  dataForReview: {
    sessionId: string
    levelId: string
  }
}

const ResultDrawer = ({
  isOpen,
  handleOnOpenChange,
  result,
  selectedOptionIndex,
  setNewQuestion,
  dataForReview,
}: ResultDrawerProps) => {
  const { feedback, question } = result
  const { correctOptionIndex, explanation } = feedback
  const { sessionId, levelId } = dataForReview
  const isCorrectAnswer = result.feedback.correctOptionIndex === selectedOptionIndex
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const classes = isCorrectAnswer
    ? {
        modalBg: "bg-emerald-50",
        text: "text-emerald-600",
        buttonBg: "bg-emerald-600 hover:bg-emerald-700",
      }
    : {
        modalBg: "bg-red-100",
        text: "text-red-600",
        buttonBg: "bg-red-600 hover:bg-red-700",
      }

  const Icon = isCorrectAnswer ? (
    <CircleCheck className="text-emerald-600" />
  ) : (
    <CircleX className="text-red-600" />
  )

  const handleOnClickBtn = () => {
    if (question) {
      setNewQuestion(question)
    } else {
      queryClient.invalidateQueries({ queryKey: ["userChallenges"] })
      navigate("/challenges/review", {
        state: { levelId, lastCompletedSessionId: sessionId },
      })
    }
  }

  return (
    <Drawer open={isOpen} onOpenChange={handleOnOpenChange} dismissible={false}>
      <DrawerContent
        className={`border-none ${classes.modalBg} mx-auto max-w-[780px] p-4 [&>*:first-child]:hidden`}
      >
        <DrawerHeader className="gap-5 p-0 pb-6 pt-3">
          <DrawerTitle className="flex items-center justify-start gap-2">
            {Icon}
            <span className={`text-sm font-bold ${classes.text}`}>
              {isCorrectAnswer ? "¡Correcto!" : "Incorrecto"}
            </span>
          </DrawerTitle>
          <DrawerDescription className="flex flex-col items-start gap-2">
            {!isCorrectAnswer && (
              <span className={`text-base font-medium ${classes.text}`}>
                Respuesta correcta:{" "}
                <span className="uppercase">{getLetterByIndex(correctOptionIndex)}</span>
              </span>
            )}

            <span className={`text-sm font-normal ${classes.text}`}>
              {explanation.endsWith(".") ? explanation : `${explanation}.`}
            </span>
          </DrawerDescription>
        </DrawerHeader>
        <DrawerFooter className="p-0">
          <Button
            className={`rounded-[8px] ${classes.buttonBg} flex h-max w-full items-center justify-center gap-2 p-2`}
            onClick={handleOnClickBtn}
          >
            <span className="text-white">{question ? "Continuar" : "Ver resumen"}</span>
            <ChevronRight className="text-white" />
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default ResultDrawer
