import { useUser } from "@/modules/user/stores"
import { api } from "@/services/api"
import { CreateDocumentErrors, type CreateDocumentData } from "@/services/api/documents/create"
import type { ClientError } from "@educabot/fetcher"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export const useCreateDocument = () => {
  const { user, setUser } = useUser()
  const mutation = useMutation({
    mutationKey: ["create-document"],
    mutationFn: async (data: CreateDocumentData) => await api.documents.create(data),
    onSuccess: ({ data }) => {
      setUser({
        ...user,
        documents: [data, ...user.documents],
      })
    },
    onError: (error: ClientError) => {
      if (error.code === CreateDocumentErrors.U1 && error.statusCode === 400) {
        toast.error(error.message)
        return
      }
      toast.error("Hubo un error al tratar de subir el archivo.", {
        description: "Por favor intentalo de nuevo más tarde.",
      })
    },
  })

  return mutation
}
