import Protected from "@/modules/auth/features/protected-route"
import Login from "@/pages/login"
import { Suspense } from "react"
import { Navigate, type RouteObject } from "react-router-dom"

const routes: RouteObject = {
  path: "login",
  element: (
    <Protected redirect="/dashboard">
      <Suspense>
        <Login />
      </Suspense>
    </Protected>
  ),
  children: [
    {
      path: "*",
      element: <Navigate to="/login" replace />,
    },
  ],
}

export default routes
