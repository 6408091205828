import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import PlanPresentationStep from "./plan-presentation-step"
import PlanSelectionStep from "./plan-selection-step"
import usePlanModal from "../stores/use-plan-modal"

interface PlanDialogProps {
  children: React.ReactNode
}

export default function PlanDialog({ children }: PlanDialogProps) {
  const { isOpen, step, setClose, setStep } = usePlanModal()

  const handleNext = () => {
    setStep(1)
  }

  const handleDialogClose = (open: boolean) => {
    setClose()
    if (!open) {
      setStep(0)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleDialogClose}>
      {children}
      <DialogContent className="flex w-[90%] max-w-[90%] flex-col rounded-md sm:w-[500px] md:w-[600px] lg:w-[600px]">
        <DialogHeader>
          <DialogTitle>Actualiza tu plan</DialogTitle>
        </DialogHeader>
        <div className="flex">
          {step === 0 && <PlanPresentationStep next={handleNext} />}
          {step === 1 && <PlanSelectionStep />}
        </div>
      </DialogContent>
    </Dialog>
  )
}
