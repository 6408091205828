import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog"
import { useDeleteChallengeModal } from "../../stores/use-delete-challenge-modal-store"
import { Button } from "@/components/button"
import useDeleteChallengeMutation from "../../queries/use-delete-challenge-mutation"
import { toast } from "sonner"

const DeleteChallengeModal = () => {
  const { isOpen, challengeId, closeModal } = useDeleteChallengeModal()
  const { isPending, mutateAsync } = useDeleteChallengeMutation()

  const handleOnClose = () => {
    if (isPending) return
    closeModal()
  }

  const handleOnDelete = async () => {
    try {
      await mutateAsync({ id: challengeId })
      toast.success("Desafío eliminado correctamente.")
    } catch {
      toast.error("Ha ocurrido un error al eliminar el desafío.")
    } finally {
      closeModal()
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOnClose}>
      <DialogContent className="w-fit min-w-[320px]">
        <DialogHeader className="flex items-start">
          <DialogTitle>¿Eliminar desafío?</DialogTitle>
          <DialogDescription>
            <p className="mt-2 text-left">Se eliminará tu progreso.</p>
            <p>Esta acción es irreversible.</p>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex flex-row items-center justify-end gap-1">
          <DialogClose asChild>
            <Button type="button" className="bg-gray-300 text-black" disabled={isPending}>
              Cancelar
            </Button>
          </DialogClose>

          <Button type="button" onClick={handleOnDelete} loading={isPending} disabled={isPending}>
            Eliminar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteChallengeModal
