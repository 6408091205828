import { client } from "@/config/client"

interface GenerateMindMapData {
  content: string
}

interface GenerateMindMapResponse {
  mindmap: string
}

const generate = (data: GenerateMindMapData) => {
  return client
    .post<GenerateMindMapResponse, GenerateMindMapData>(`/v1/mindmaps/generate`, data)
    .then((resp) => resp.data)
}

export default generate
