import { client } from "@/config/client"

export interface CreateThemeData {
  startPage: number
  endPage: number
  documentId: string
  learningPathId: string
}

export function create(data: CreateThemeData) {
  return client.post<void, CreateThemeData>("/v1/documents/themes", data)
}
