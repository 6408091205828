import { Home, TestTube, Users, Shapes, PencilRuler, HeartHandshake, UserCheck } from "lucide-react"
import Nav from "./side-panel/nav"
import { Link, useNavigate } from "react-router-dom"
import { useUser } from "@/modules/user/stores"
import { roleIs } from "@/utils/role-is"

export default function SidePanel() {
  const { user } = useUser()
  const navigate = useNavigate()

  const items = [
    { id: 1, icon: Home, label: "Inicio", link: "/dashboard", disabled: false },
    { id: 2, icon: TestTube, label: "Diagnóstico", link: "/diagnoses", disabled: false },
    { id: 3, icon: PencilRuler, label: "Herramientas", link: "/tools", disabled: false },
    {
      id: 4,
      icon: Shapes,
      label: "Talleres",
      link: "https://tichteam.notion.site/Talleres-Tich-Academy-4c2dd91289f44513ae14d466e8fd72c5?pvs=4",
      disabled: false,
    },
    {
      id: 5,
      icon: UserCheck,
      label: "Admin",
      link: "/admin",
      disabled: !roleIs("admin", user.role),
    },
  ]

  return (
    <nav className="border-r-1 flex h-screen w-64 flex-col border shadow-lg">
      <div className="flex h-40 justify-center pt-10">
        <img
          alt="Logo"
          src="https://storage.googleapis.com/educabot-aec-cdn-1/tich-landing/images/hat_logo.svg"
          className="h-10 w-10 cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="flex flex-1">
        <Nav items={items} />
      </div>
      <div className="flex h-40 flex-col items-center justify-end gap-6 pb-10">
        <Link to="https://chat.whatsapp.com/EhMMaXPQjBh9EIOILxI7AD" target="_blank">
          <HeartHandshake className="h-6 w-6 cursor-pointer text-secondary" />
        </Link>
        <Link
          to="https://tichteam.notion.site/La-mejor-comunidad-de-estudiantes-Tich-d58cf9ca3d494a7993b7bdb678c25478"
          target="_blank"
        >
          <Users className="h-6 w-6 cursor-pointer text-secondary" />
        </Link>
      </div>
    </nav>
  )
}
